import React, { useEffect, useState } from 'react';
import { useGetOne, useRecordContext, useTranslate } from 'react-admin';
import CardWrapper from "../../aegeon/cards/CardWrapper";
import ChartGauge from "../../aegeon/charts/ChartGauge";
import { formatDate, getConsumptionVariation, getPeriods, getWarningColor } from "../../aegeon/compute_evolution";
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
import { useTimeseriesContext } from "../../aegeon/timeseries/useTimeseriesContext";

const CardGauge = (props) => {
  const t = useTranslate();

  const moment = require('moment');
  const endDateLastDayOfLastMonth = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).set({ date: 1 }).subtract(1, 'days');
  const startDateBeginningOfTwoYearsAgo = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filter = { startDate: startDateBeginningOfTwoYearsAgo, endDate: endDateLastDayOfLastMonth };

  const [isComponentLoading, setIsComponentLoading] = useState(true);
  const [consumption, setConsumption] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [lastYearDays, setLastPeriodDays] = useState("");
  const [currentYearDays, setCurrentPeriodDays] = useState("");

  const record = useRecordContext();
  const qualityWarning = record?.fusionedEnergyQuality === "B" ? "orange" : record?.fusionedEnergyQuality === "A" ? "green" : "red"

  const EvolutionReceiver = (props) => {
    const {
      timeseries,
      isLoading,
    } = useTimeseriesContext(props);

    const record = useRecordContext(props);
    const site_id = record?.airtable?.sites?.length > 0 ? record?.airtable?.sites[0].id : undefined

    const { data } = useGetOne(
      'sites',
      { id: site_id }
    );

    const reporting_start_date = data?.airtable?.reporting_start_date

    const isData = timeseries && timeseries.length > 0

    useEffect(() => {
      if (isData) {
        let period = getPeriods(reporting_start_date, timeseries)
        let { consumptionVariation, lastYearDays, currentYearDays } = getConsumptionVariation(timeseries, period)
        setConsumption(consumptionVariation)
        setLastPeriodDays(lastYearDays)
        setCurrentPeriodDays(currentYearDays)
        setIsComponentLoading(false)
        setStartDate(formatDate(period.currentPeriodStartDate))
        setEndDate(formatDate(period.currentPeriodEndDate))
      }
      else if (!isLoading && timeseries && timeseries.length === 0) {
        setIsComponentLoading(false)
      }
    }, [isLoading, timeseries, isData, reporting_start_date]);

    return null;
  };

  let subtitle_text = consumption && startDate ? t('devices.evolution.subtitle') + ' ' + (t('devices.evolution.subtitle_dates_1') + startDate + t('devices.evolution.subtitle_dates_2') + endDate + t('devices.evolution.subtitle_dates_3')) : t('devices.evolution.subtitle') + '?'

  let warning_color = getWarningColor(lastYearDays, currentYearDays, consumption, "month")
  let warning_text = t("devices.evolution.tooltip_text_source")
  warning_text += (warning_color === 'red' || warning_color === 'orange') ? t("devices.evolution.tooltip_warning_1") + lastYearDays + t("devices.evolution.tooltip_warning_2") + currentYearDays + t("devices.evolution.tooltip_warning_3") : (warning_color === "green") ? t("devices.evolution.tooltip_text_1") + currentYearDays + t("devices.evolution.tooltip_text_2") : t("devices.evolution.tooltip_no_data")
  warning_text += (qualityWarning === "red") ? t("devices.evolution.subtitle_red") : (qualityWarning === "orange") ? t("devices.evolution.subtitle_orange") : t("devices.evolution.subtitle_green")

  return (
    <CardWrapper resource="devices" type="evolution" subtitleCallBack={subtitle_text} custom_tooltip={warning_text} isRedWarning={warning_color === "red" || qualityWarning === "red"} isOrangeWarning={warning_color === 'orange' || qualityWarning === "orange"} >
      <TimeseriesField source={"fusioned_energy"} filter={filter} aggregation={{ period: 'MONTH', operation: 'SUM' }} >
        <EvolutionReceiver />
        <ChartGauge
          consumptionVariation={consumption}
          isLoading={isComponentLoading}
        />
      </TimeseriesField>
    </CardWrapper>
  )
};

export default CardGauge;