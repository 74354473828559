import { useContext } from 'react';
import { useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import QueryInfiniteField from '../../aegeon/query/QueryInfiniteField';
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
import ChartSubDeviceHorizontalBar from '../Cards/ChartSubDeviceHorizontalBar';

const SubdevicesComponent = (props) => {
    const moment = require('moment');
    const t = useTranslate();
    const startDateBeginningOfTwoYearsAgo = moment().set({ year: moment().year() - 2, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
    const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const startDate10years = moment().set({ year: moment().year() - 10, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const filter_all_data = { startDate: startDate10years, endDate: today };
    const { record } = useContext(RecordContext);

    const source = {
        name: t('devices.sources.enedis_billed_energy'),
        source: 'billed_energy',
        filter: filter_all_data,
        aggregation: { period: 'MONTH', operation: 'SUM' }
    }

    const isBilledEnergy = ["enedisMeter", "grdfMeter"].includes(record?.deviceType);

    return (
        isBilledEnergy ?
            <TimeseriesField source={source.source} filter={source.filter} aggregation={source.aggregation}>
                <QueryInfiniteField query_name="getDeviceSubdevicesTimeseries" params={{ id: record.id, time: { between: [startDateBeginningOfTwoYearsAgo, endDate] } }}>
                    <ChartSubDeviceHorizontalBar start_date={startDateBeginningOfTwoYearsAgo} end_date={endDate} />
                </QueryInfiniteField>
            </TimeseriesField>
            :
            <QueryInfiniteField query_name="getDeviceSubdevicesTimeseries" params={{ id: record.id, time: { between: [startDateBeginningOfTwoYearsAgo, endDate] } }}>
                <ChartSubDeviceHorizontalBar start_date={startDateBeginningOfTwoYearsAgo} end_date={endDate} />
            </QueryInfiniteField>
    )
};

export default SubdevicesComponent;
