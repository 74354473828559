import { Card, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useGetOne, useTranslate } from 'react-admin';
import { RecordContext } from '../../../App';
import globalUseStyles from '../../../styles/globalCustomStyles';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import ChartGauge from '../../aegeon/charts/ChartGauge';
import { getConsumptionVariation, getPeriods } from "../../aegeon/compute_evolution";
import { export_row_to_excel } from '../../aegeon/helper';
import QueryField from '../../aegeon/query/QueryField';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import TimeseriesField from "../../aegeon/timeseries/TimeseriesField";
import { useTimeseriesContext } from "../../aegeon/timeseries/useTimeseriesContext";
import ChooseMonthButton from '../../Buttons/ChooseMonthButton';
import ExportButton from '../../Buttons/ExportButton';
import CustomTable from '../../Table/CustomTable';

const EvolutionPageDetails = (props) => {
  const { data, isLoading } = useQueryContext(props);
  const { record, setRecord } = useContext(RecordContext);
  const t = useTranslate();
  const theme = useTheme();
  const globalClasses = globalUseStyles();
  const {
    timeseries,
    isLoading: isTimeseriesLoading,
  } = useTimeseriesContext(props);
  const startDate = props.startDate;

  const site_id = record?.airtable?.sites?.length > 0 ? record?.airtable?.sites[0].id : undefined
  const { data: site } = useGetOne(
    'sites',
    { id: site_id }
  );

  const reporting_start_date = site?.airtable?.reporting_start_date
  const initialPeriods = getPeriods(reporting_start_date, undefined)

  const [firstPeriod, setFirstPeriod] = useState({ startDate: initialPeriods.previousPeriodStartDate, endDate: initialPeriods.previousPeriodEndDate });
  const [secondPeriod, setSecondPeriod] = useState({ startDate: initialPeriods.currentPeriodStartDate, endDate: initialPeriods.currentPeriodEndDate });
  const [consumptionObject, setConsumptionObject] = useState(null);
  const [quality, setQuality] = useState("A");

  useEffect(() => {
    if (data && !isLoading) {
      setRecord(data)
    }
    else {
      setRecord(null)
    }
  }, [data, isLoading]);


  useEffect(() => {
    if (timeseries && !isTimeseriesLoading) {
      props.setDisabled(false)

      let period = getPeriods(reporting_start_date, timeseries)

      if (firstPeriod.startDate && firstPeriod.endDate && secondPeriod.startDate && secondPeriod.endDate) {
        period = {
          previousPeriodEndDate: firstPeriod.endDate,
          previousPeriodStartDate: firstPeriod.startDate,
          currentPeriodEndDate: secondPeriod.endDate,
          currentPeriodStartDate: secondPeriod.startDate,
        }
      }
      let { consumptionVariation, lastYearDays, currentYearDays, lastYearMonths, currentYearMonths, lastYearConsumption,
        currentYearConsumption } = getConsumptionVariation(timeseries, period)
      setConsumptionObject({
        consumptionVariation, lastYearDays, currentYearDays, lastYearMonths, currentYearMonths, lastYearConsumption,
        currentYearConsumption
      })
      let quality = getComparisonQuality(firstPeriod, secondPeriod, lastYearDays, currentYearDays)
      setQuality(quality)
      props.setDataCallback(timeseries, quality)
    }
  }, [isTimeseriesLoading, timeseries, firstPeriod, secondPeriod]);

  const getMissingPeriods = (record, period) => {
    let missing_values = record?.properties?.find((property) => property?.name === "fusioned_energy")?.quality?.missing_values
    missing_values = missing_values?.filter((missing_value) => {
      let date = moment(missing_value, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      let period_end_date = moment(period.endDate).add(1, 'months')
      return date >= period.startDate && date <= period_end_date
    })
    let missing_months = missing_values?.map((missing_value) =>
      moment(missing_value, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("MM/YYYY")
    )
    let missing_months_set = new Set(missing_months)
    let missing_months_count = {}
    missing_months_set.forEach((missing_month) => {
      missing_months_count[missing_month] = missing_months.filter((month) => month === missing_month).length
    })
    missing_months = Object.keys(missing_months_count).map((key) => {
      return key + " (" + missing_months_count[key] + " jours)"
    })

    if (missing_months.length === 0) {
      return " "
    }
    return missing_months.join(", ")
  }

  const getComparisonQuality = (firstPeriod, secondPeriod, lastYearDays, currentYearDays) => {
    let missingFirstPeriod = getMissingPeriods(record, firstPeriod)
    let missingSecondPeriod = getMissingPeriods(record, secondPeriod)
    let firstPeriodStart = moment(firstPeriod.startDate).format("DD/MM")
    let firstPeriodEnd = moment(firstPeriod.endDate).format("DD/MM")
    let secondPeriodStart = moment(secondPeriod.startDate).format("DD/MM")
    let secondPeriodEnd = moment(secondPeriod.endDate).format("DD/MM")
    if (firstPeriodStart !== secondPeriodStart || firstPeriodEnd !== secondPeriodEnd) {
      return "D"
    }
    if (lastYearDays !== currentYearDays) {
      return "C"
    }
    if (missingFirstPeriod !== " " || missingSecondPeriod !== " ") {
      return "B"
    }
    return "A"
  }

  const quality_colors = {
    "A": theme.palette.gradation.low,
    "B": theme.palette.gradation.midLow,
    "C": theme.palette.gradation.midHigh,
    "D": theme.palette.gradation.high
  }

  const rows = [
    { name: t("devices.evolution.table.quality"), value: quality, tooltip_info: 'devices.evolution.table.quality_tooltip_info', tooltip_info_color: quality_colors[quality] },
    { name: t("devices.evolution.table.reference_period"), value: firstPeriod.startDate ? firstPeriod.startDate.toLocaleDateString(localStorage.getItem("language"), { month: "2-digit", year: "numeric" }) + " - " + firstPeriod.endDate.toLocaleDateString(localStorage.getItem("language"), { month: "2-digit", year: "numeric" }) : " " },
    { name: t("devices.evolution.table.comparison_period"), value: secondPeriod.startDate ? secondPeriod.startDate.toLocaleDateString(localStorage.getItem("language"), { month: "2-digit", year: "numeric" }) + " - " + secondPeriod.endDate.toLocaleDateString(localStorage.getItem("language"), { month: "2-digit", year: "numeric" }) : " " },
    { name: t("devices.evolution.table.missing_reference_period"), value: getMissingPeriods(record, firstPeriod) },
    { name: t("devices.evolution.table.missing_comparison_period"), value: getMissingPeriods(record, secondPeriod) },
    { name: t("devices.evolution.table.months_reference_period"), value: consumptionObject?.lastYearMonths?.join(", ") },
    { name: t("devices.evolution.table.months_comparison_period"), value: consumptionObject?.currentYearMonths?.join(", ") },
    { name: t("devices.evolution.table.last_period_consumption"), value: consumptionObject?.lastYearConsumption?.toFixed(2) },
    { name: t("devices.evolution.table.current_period_consumption"), value: consumptionObject?.currentYearConsumption?.toFixed(2) },
  ]

  return (
    <Card className={`${globalClasses.DetailCard} ${globalClasses.DetailCardTable}`}>
      <Grid2 container direction="row" justifyContent="center" alignItems="center"
        spacing={5}
        style={{ padding: '0 !important' }}
      >
        <Grid2 item="true" small={12} medium={6} big={6} >
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t(`devices.evolution.title`)}</Typography>
            {/* {<CustomTooltip title={t(`devices.evolution.tooltip_text`)} icon={<InfoTooltipIcon />} />} */}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t(`devices.evolution.details_subtitle`)}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <div className={globalClasses.evolutionButtons}>
              <ChooseMonthButton
                title={t(`devices.evolution.first_period`)}
                period={firstPeriod}
                setPeriod={setFirstPeriod}
                startDate={startDate}
              />
              <ChooseMonthButton
                title={t(`devices.evolution.second_period`)}
                period={secondPeriod}
                setPeriod={setSecondPeriod}
                startDate={startDate}
              />
            </div>
            <div style={{ width: "100%", height: "80%" }}>
              <ChartGauge
                consumptionVariation={consumptionObject?.consumptionVariation}
                isLoading={isTimeseriesLoading}
              />
            </div>
          </div>
        </Grid2>
        <Grid2 item="true" small={12} medium={6} big={6} >
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t(`devices.evolution.details_title`)}</Typography>
            {/* {<CustomTooltip title={t(`devices.evolution.tooltip_text`)} icon={<InfoTooltipIcon />} />} */}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t(`devices.evolution.details_subtitle_2`)}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <CustomTable rows={rows} defaultTooltip={"sites.no_data_tooltip"} />
          </div>
        </Grid2>
      </Grid2>
    </Card>
  )
};

const EvolutionPageDetailsWithContext = (props) => {
  const moment = require('moment');
  const t = useTranslate();
  const endDateLastDayOfLastMonth = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).set({ date: 1 }).subtract(1, 'days');
  const startDate = moment().set({ year: 2019, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filter = { startDate: startDate, endDate: endDateLastDayOfLastMonth };
  const [data, setData] = useState(null);
  const [quality, setQuality] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const setDataCallback = (data, quality) => {
    setData(data)
    setQuality(quality)
  }

  const handleExport = (name) => {
    var sheets_data = [];
    if (data) {
      const sheetName = "Série AEGEON"
      const quality_note = `${t('devices.evolution.table.quality')} : ${quality} - ${t('devices.evolution.table.quality_note')}`
      let rows = [[quality_note], ["Date", "Consommations (kWh PCS)", "Source"]]
      data.forEach((row) => {
        let date = moment(row.time).format("MM/YYYY")
        let value = Math.round(row.value * 100) / 100
        rows.push([date, value, "Série AEGEON"])
      })
      sheets_data.push({ sheetName: sheetName, data: rows });
      export_row_to_excel(sheets_data, `${t('devices.exports.table')}${name}.xlsx`);
    };
  }

  return (
    <DetailsShow title={t("devices.evolution.title")} resource="devices" lessMargin={true} tooltip={<ExportButton onClick={handleExport} disabled={disabled} />}>
      <QueryField query_name="getDevice">
        <TimeseriesField source={"fusioned_energy"} filter={filter} aggregation={{ period: 'MONTH', operation: 'SUM' }} >
          <EvolutionPageDetails {...props} setDisabled={setDisabled} setDataCallback={setDataCallback} startDate={startDate} />
        </TimeseriesField>
      </QueryField>
    </DetailsShow>
  )
}

export default EvolutionPageDetailsWithContext;