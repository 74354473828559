import React, { useEffect } from "react";
import { useRecordContext, useTranslate } from 'react-admin';
import CustomLargeTable from "../../Table/CustomLargeTable";
import ChartNoData from "./ChartNoData";

const ChartOccupationRateTable = (props) => {
    const record = useRecordContext();
    const t = useTranslate();

    const convertData = (headers, values) => {
        if (values) {
            let data = [['Année'], ["Taux d'occupation (%)"]]
            headers.forEach((header, index) => {
                data[0].push(header)
                data[1].push(values[index])
            })
            return data
        }
        return []
    }

    const headers = ["2019", "2020", "2021", "2022", "2023", "2024"]
    const values = headers.map((header) => record && record?.airtable[`rental_occupation_rate_${header}`] ? Math.round(record?.airtable[`rental_occupation_rate_${header}`] * 10000) / 100 : "")

    useEffect(() => {
        if (values.some((value) => value !== "")) {
            props.setSubtitleCallback(t("sites.table.occupation_rate.subtitle"))
            props.setDataCallback && props.setDataCallback(convertData(headers, values))
        }
    }, [values]);

    return (
        values.some((value) => value !== "") ?
            <CustomLargeTable
                head={["", ...headers]}
                rows={[{ name: "Taux (%)", values: values }]}
                disableMissingDataTooltip={true}
                disabledBoldLastValue={true}
            />
            :
            <ChartNoData />
    )
}

export default ChartOccupationRateTable;