import { Card } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ExportButton from '../../Buttons/ExportButton';
import AnalysisCardDetails from '../../aegeon/cards/AnalysisCardDetails';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import { export_row_to_excel } from '../../aegeon/helper';


const SiteAnalysisTextDetails = (props) => {
  const t = useTranslate();
  const globalClasses = globalUseStyles();
  const [data, setData] = useState();
  const title = t('sites.analyse.title');

  const [disabled, setDisabled] = useState(true);


  const setDataCallback = (childData) => {
    if (childData && !data) {
      setData(childData)
    }
  }

  useEffect(() => {
    if (data !== undefined) {
      setDisabled(false)
    }
  }, [data])

  const handleExport = (name) => {
    var sheets_data = [];
    if (data !== null) {
      data.map((item, index) => {
        const date = new Date(item.created_at).toLocaleDateString(localStorage.getItem("language"), { year: 'numeric', month: 'long', day: 'numeric' })
        sheets_data.push({ sheetName: index + " - " + date, data: [["Date de l'analyse", date], ["Résultat de l'analyse", item.text]] });
      }
      )
      export_row_to_excel(sheets_data, `${t('resources.analyse.export_name')}${name}.xlsx`);
    };
  }

  return (
    <DetailsShow title={title} resource="sites" lessMargin={true} tooltip={<ExportButton onClick={handleExport} disabled={disabled} />} >
      <Grid2 container spacing={2}>
        <Grid2 item="true" small={12} medium={12} big={12} key="1">
          <Card className={globalClasses.DetailCard}>
            <AnalysisCardDetails analysisClass="SITE" analysisType="GENERAL" setDataCallback={setDataCallback} />
          </Card>
        </Grid2>
      </Grid2>
    </DetailsShow>
  )
};

export default SiteAnalysisTextDetails;
