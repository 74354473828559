import "@fontsource/montserrat/500.css";
import { makeStyles } from "@mui/styles";
import AEGEON_PLATFORM_BACKGROUND from "../assets/AEGEON_PLATFORM_BACKGROUND.png";
import { darken, lighten } from "@mui/material/styles";

const getBackgroundColor = (color, mode) => (mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7));
const getHoverBackgroundColor = (color, mode) => (mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6));

const getSelectedBackgroundColor = (color, mode) => (mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5));

const getSelectedHoverBackgroundColor = (color, mode) => (mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4));

const globalUseStyles = makeStyles((theme) => ({
  loginContent: {
    height: "100vh",
    backgroundImage: `url(${AEGEON_PLATFORM_BACKGROUND})`,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
  },
  loginLogo: {
    width: "183px !important",
    height: "123px !important",
  },
  loginItems: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loginFormContent: {
    width: 300,
    backgroundColor: "white",
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "8px",
    marginTop: "40%",
    padding: "24px",
  },
  loginFormButton: {
    backgroundColor: "#71b9a1",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "14px",
  },
  loginFormIcon: {
    margin: theme.spacing(0.3),
  },
  loginForm: {
    "& .MuiInputBase-input": {
      fontFamily: "Montserrat",
      fontWeight: "400",
      fontSize: "14px",
    },
    "& .MuiInputLabel-root": {
      fontFamily: "Montserrat",
      fontWeight: "400",
      fontSize: "13px",
    },
    "& .MuiFormControl-root": {
      marginBottom: "0",
    },
  },
  loginText: {
    fontWeight: "600 !important",
    fontSize: "24px !important",
    textAlign: "center",
    paddingBottom: "5%",
  },
  usersIcon: {
    padding: "0px 5px",
  },
  home: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "500px",
  },
  homeTitle: {
    display: "flex",
    paddingBottom: "24px",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  aegeonCentered: {
    width: "182px !important",
    height: "31px !important",
  },
  platformCentered: {
    width: "88px !important",
    height: "16px !important",
  },
  homeMessage: {
    color: "var(--dark, #1B232A)",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    marginTop: "24px",
  },
  homeSearchBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "24px",
    "& .header-wrapper": {
      display: "flex",
    },
  },
  appBarContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  appBarSearchArea: {
    height: "56px",
    paddingLeft: "37px",
  },
  searchBarAndButton: {
    display: "flex",
    flexDirection: "row",
  },
  appBarSpacer: {
    flex: 1,
  },
  appBarTitle: {
    display: "contents",
  },
  appBarUserMenu: {
    display: "flex",
    flexDirection: "row",
    " & .MuiButtonBase-root": {
      padding: "0 !important",
    },
  },
  appBarText: {
    marginRight: "16px",
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
  },
  appBarUserText: {
    fontWeight: "600 !important",
  },
  appBarGroupText: {
    marginTop: "-5px",
  },
  appBarUserButton: {
    backgroundColor: "white",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
  },
  appBarSearch: {
    backgroundColor: "white",
    borderRadius: "24px",
    display: "flex",
    height: "50px",
  },
  appBarSearchIcon: {
    padding: "0 16px",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      color: "#71b9a1",
    },
  },
  appBarSearchInput: {
    color: "inherit",
    height: "100%",
    width: "50vh",
    "& .MuiInputBase-input": {
      padding: "8px 8px 8px 0",
      transition: "width",
      width: "100%",
      fontSize: "12px",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  },
  layoutRoot: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    position: "relative",
    height: "100%",
  },
  layoutAppFrame: {
    display: "flex",
    flexDirection: "row",
  },
  layoutContentWithSidebar: {
    backgroundColor: "#1B232A",
    position: "fixed",
    zIndex: 1,
    height: "100%",
  },
  layoutSidebar: {
    height: "100vh",
  },
  layoutContent: {
    padding: "30px 30px 30px 0px",
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: "100%",
    // opacity: localStorage.getItem("searchActive") ? 0.5 : 1,
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    width: "400px",
  },
  sidebarClosed: {
    maxWidth: "112px",
  },
  sidebarTopContainer: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
  },
  sidebarBottomContainer: {
    position: "fixed",
    top: "113px",
    width: "inherit",
  },
  sidebarLogo: {
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
  sidebarAegeonPlatform: {
    marginTop: "28px !important",
    width: "183px !important",
    height: "57px !important",
  },
  sidebarButton: {
    color: "#A6ACAF",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  },
  sidebarButtonOpen: {
    paddingLeft: "80px",
  },
  sidebarButtonClosed: {
    transform: "translateX(-28px)",
  },
  sidebarMainMenu: {
    height: "60px",
    display: "flex",
    alignItems: "center",
    marginTop: "28px",
  },
  sidebarMainMenuOpen: {
    marginLeft: "28px",
  },
  sidebarMainMenuClosed: {
    textAlign: "center",
  },
  sidebarMainMenuText: {
    color: "#A6ACAF !important",
    fontSize: "16px !important",
    fontWeight: "300 !important",
    textTransform: "uppercase",
  },
  sidebarMenuClosed: {
    textIndent: "500px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "& .MuiSvgIcon-root": {
      marginRight: "500px !important",
    },
  },
  globalContainer: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    margin: "auto",
    [theme.breakpoints.down(980)]: {
      width: "95%",
      flexDirection: "column",
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "0.5rem",
    padding: "1rem",
    backgroundColor: "white",
    border: "0.5px solid #2e2e2e33",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px #2e2e2e33",
  },
  cardLeftPart: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    justifyContent: "space-between",
  },
  cardRightPart: {
    display: "flex",
    flexDirection: "column",
    width: "65%",
    margin: "auto",
    justifyContent: "space-between",
  },
  insideCardRightPart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    borderRadius: "25px",
  },
  customTextField: {
    fontSize: "1.1em",
    color: "#000000",
    fontWeight: "bold",
    textAlign: "center",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    padding: "0.5em",
    marginBottom: "1rem",
  },
  emptyCustomTextField: {
    fontSize: "1.1em",
    color: "#000000",
    textAlign: "center",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px  #2e2e2e33",
    padding: "0.7em",
    marginBottom: "1rem",
  },
  customBooleanField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    padding: "0.5em",
    margin: "0.2rem 0 1rem",
  },
  customChipField: {
    fontSize: "1.1em",
    fontWeight: "bold",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    backgroundColor: "white",
    height: "45px",
    margin: "0.2rem 0 1rem",
  },
  usersInAlertCustomChipField: {
    fontSize: "1.1em !important",
    fontWeight: "bold !important",
    backgroundColor: "white !important",
    margin: 0,
  },
  customBoldTextField: {
    fontSize: "1.1em",
    fontWeight: "bold",
    color: "#000000",
  },
  customArrayField: {
    fontSize: "1.1em",
    color: "#000000",
    fontWeight: "bold",
    textAlign: "center",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    marginBottom: "1rem",
    overflow: "hidden",
  },
  customReferenceArrayField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    padding: "0.5em",
    margin: "0.2rem 0 1rem",
  },
  customSpanField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: "1.1em",
    fontWeight: "bold",
    border: "1px solid #000000",
    borderRadius: "15px",
    boxShadow: "1px 3px 2px  #2e2e2e33",
    padding: "0.7em 0.5em",
    margin: "0.2rem 0 1rem",
  },
  mapContainer: {
    border: "1px solid #2e2e2e33",
    borderRadius: "25px",
    overflow: "hidden",
    padding: "0",
  },
  customPhoneContainer: {
    marginBottom: "1.5em !important",
  },
  customPhoneField: {
    width: "100% !important",
    height: "2.7em !important",
    fontSize: "1.1em !important",
    color: "#000000 !important",
    fontWeight: "bold !important",
    textAlign: "center !important",
    border: "1px solid #000000 !important",
    borderRadius: "15px !important",
    boxShadow: "1px 3px 2px #2e2e2e33 !important",
    padding: "0.5em !important",
    "&:hover": {
      cursor: "text",
    },
  },
  customPhoneInput: {
    fontSize: "1.1em !important",
    width: "100% !important",
    height: "1.1876em !important",
    borderRadius: "0 !important",
    border: "none !important",
    boxShadow: "0 1px #8f8f8f !important",
    backgroundColor: "#f5f5f5 !important",
    paddingTop: "1.5em !important",
    paddingBottom: "1.5em !important",
    "&:hover": {
      backgroundColor: "#DEDEDE !important",
    },
    "&:focus": {
      boxShadow: "0 2px black !important",
      borderBottomHeight: "10px !important",
      backgroundColor: "#E8E8E8 !important",
      fontWeight: "bold !important",
      transition: "box-shadow 0.5s ease-in-out",
    },
  },
  buildingsFormContainer: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    margin: "auto",
    backgroundColor: "white",
    border: "0.5px solid #2e2e2e33",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px #2e2e2e33",
    [theme.breakpoints.down(980)]: {
      width: "95%",
      flexDirection: "column",
    },
  },
  dashboards_ItemCard: {
    background: "white !important",
    border: "0.5px solid #2e2e2e33 !important",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px #2e2e2e33 !important",
  },
  dashboards_ItemContainer: {
    display: "flex",
    alignContent: "stretch",
    height: "100%",
    justifyContent: "space-between",
    flexDirection: "column",
    "&:hover": {
      opacity: "1",
    },
  },
  dashboards_GraphContainer: {
    display: "flex",
    padding: "8px",
    flex: 1,
  },
  dashboards_ItemToolBar: {
    zIndex: "500",
    display: "flex",
    position: "absolute",
    left: "0px",
    top: "0px",
    opacity: "0.5",
    transition: "all ease 0.2s",
    "& button": {
      marginRight: "8px",
    },
  },
  dashboards_buttonBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dashboards_EmptyCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "25px",
    boxShadow: "1px 3px 2px #2e2e2e33 !important",
  },
  Grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  Card: {
    "&:hover": {
      boxShadow: "0px 0px 12px #A6ACAF, 0px 4px 16px rgba(4, 71, 66, 0.3)",
    },
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    minWidth: "400px",
    boxShadow: "0px 4px 24px rgba(4, 71, 66, 0.1)",
    padding: "20px 24px",
    [theme.breakpoints.down(520)]: {
      minWidth: "auto",
    },
  },
  unclickableCard: {
    "&:hover": {
      boxShadow: "0px 4px 24px rgba(4, 71, 66, 0.1)",
    },
  },
  cardTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardIconTitle: {
    display: "flex",
    flexDirection: "row",
  },
  Detail: {
    margin: "20px 0px 0px 52px",
  },
  DetailWithLessMargin: {
    margin: "12px -8px 0px 44px",
  },
  DetailTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  DetailCard: {
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    minWidth: "200px",
    minHeight: "200px",
    boxShadow: "0px 4px 4px rgba(4, 71, 66, 0.1)",
    padding: "17px 36px",
    background: "#FFFFFF",
  },
  SubDevicesAggregation: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    padding: "0px 0px 10px 0px",
  },
  DetailCardReduced: {
    minHeight: "auto !important",
  },
  DetailQualityText: {
    marginTop: "50px",
  },
  DetailCardTable: {
    padding: "0px 16px",
  },
  DetailCardTableTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  DetailCardSecondTitle: {
    paddingTop: "20px",
    paddingBottom: "10px",
    "& .MuiTypography-root": {
      color: "#5F6971 !important",
      fontSize: "20px !important",
    },
  },
  DetailCardTableSubtitle: {
    ...theme.typography.h9,
    paddingBottom: "22px",
    minHeight: "20px",
  },
  DetailCardTableCells: {
    "& .MuiTableCell-body:not(:first-child)": {
      padding: "5px 0px !important",
    },
  },
  DetailCardGraph: {
    height: "320px",
  },
  DetailCardGraphDouble: {
    height: "600px",
  },
  CardHeader: {
    textAlign: "left",
    padding: "0px",
    "& .MuiCardHeader-title": {
      ...theme.typography.h3,
    },
  },
  cardSubtitle: {
    ...theme.typography.h9,
    height: "40px",
  },
  ChartCard: {
    width: "100%",
    height: "100%",
  },
  BackButtonCustom: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  BackButton: {
    margin: "6px 10px",
    backgroundColor: "rgba(113, 185, 161, 0.20)",
    "&:hover": {
      backgroundColor: "rgba(113, 185, 161, 0.30)",
    },
  },
  Show: {
    backgroundColor: "#EBE9E4",
  },
  CardTable: {},
  Table: {
    "& .MuiTable-root": {
      minWidth: "auto",
    },
  },
  generalInformation: {
    marginTop: "-15px !important",
  },
  Cell: {
    padding: "8px 5px !important",
  },
  tableCell: {
    "&.MuiTableCell-head": {
      backgroundColor: "#2E2E2E",
      color: "#FFFFFF",
    },
    "&.MuiTableCell-body": {
      fontSize: "13px",
    },
  },
  tableCells: {
    padding: "0px 5px",
    textAlign: "center",
  },
  tableCellsFirst: {
    fontWeight: "bold",
  },
  tableCellLeft: {
    padding: "0px 5px",
    textAlign: "right",
    fontWeight: "bold",
    width: "40%",
  },
  tableCellLeftWithoutWidth: {
    padding: "0px 5px",
    textAlign: "right",
    fontWeight: "bold",
  },
  tableCellRight: {
    padding: "0px 5px",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    textAlign: "left",
    width: "60%",
  },
  tableCellEqualSize: {
    width: "50%",
  },
  tableCellBigger: {
    width: "60%",
  },
  tableNoData: {
    backgroundColor: "#F4802133",
    alignItems: "center",
    justifyContent: "center !important",
  },
  customTableCellEstimated: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    color: "#37424AAA !important",
  },
  customTableCellRegulated: {
    backgroundColor: "#80C3FF50",
    color: "#37424AAA !important",
  },
  customTableCellMissing: {
    backgroundColor: "#F4802133",
    color: "#F48021 !important",
  },
  customTableCellRegulatedEstimated: {
    background: "linear-gradient(135deg, rgba(0, 0, 0, 0.08) 50%, #80C3FF50 50%)",
    color: "#37424AAA !important",
  },
  customTableCellRegulatedMissing: {
    background: "linear-gradient(135deg, #F4802133 50%, #80C3FF50 50%)",
    color: "#37424AAA !important",
  },
  customTableCellEstimatedMissing: {
    background: "linear-gradient(135deg, rgba(0, 0, 0, 0.08) 50%, #F4802133 50%)",
    color: "#37424AAA !important",
  },
  customTableCellRegulatedEstimatedMissing: {
    background:
      "linear-gradient(135deg, #F4802133 33%, rgba(0, 0, 0, 0.12) 33%, rgba(0, 0, 0, 0.12) 66%, #80C3FF50 66%)",
    color: "#37424AAA !important",
  },
  tableCellWithIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableCellWithIconCentered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px 5%",
  },
  tableCellIcon: {
    width: "12px",
    height: "12px",
    padding: "0px 3px",
    color: "#71B9A1",
  },
  tableCellIconMultiple: {
    width: "12px",
    height: "12px",
    padding: "0px 3px",
    color: "#71B9A1",
    paddingRight: "10px",
  },
  tableCellNameIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& svg": {
      width: "18px",
      height: "18px",
      color: "black",
    },
  },
  tableCellNoData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#F48021",
  },
  tableCellOneLine: {
    display: "-webkit-box",
    textAlign: "left",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      "-webkit-line-clamp": "unset",
    },
  },
  tableCellMultipleLines: {
    display: "-webkit-box",
    "-webkit-line-clamp": "unset !important",
  },
  tableCellNoDataCentered: {
    color: "#F48021",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F5F5F5",
    },
    "&:last-child td, &:last-child th": {
      borderBottom: 0,
    },
  },
  tableRowLink: {
    "&:hover": {
      backgroundColor: "#71B9A150 !important",
      boxShadow: "0px 0px 4px #71B9A150",
    },
    backgroundColor: "#71B9A120 !important",
    cursor: "pointer",
  },
  customTableRow: {
    "& td": {
      color: "#1B232A",
      fontSize: "13px !important",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: "rgba(113, 185, 161, 0.2)",
    },
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  customTableCell: {
    whiteSpace: "nowrap",
    fontSize: 13,
    "&.MuiTableCell-head": {
      backgroundColor: "white",
      padding: "5px",
      border: "1px solid rgba(224, 224, 224, 1) !important",
      textAlign: "center",
      fontWeight: "bold",
      "&:first-child": {
        borderRight: "1px solid #A6ACAF !important",
      },
      "&:not(:first-child)": {
        borderRight: "1px solid rgba(224, 224, 224, 1) !important",
      },
      borderBottom: "1px solid #A6ACAF !important",
    },
    "&.MuiTableCell-body": {
      fontSize: "13px",
      "&:first-child": {
        padding: "5px",
        borderRight: "1px solid #A6ACAF",
        textAlign: "center",
        fontWeight: "bold",
      },
      "&:not(:first-child)": {
        padding: "5px",
        textAlign: "center",
        border: "1px solid rgba(224, 224, 224, 1)",
      },
    },
  },
  customLinkIconTableCell: {
    display: "flex",
    placeContent: "space-between",
  },
  customTableCellTotal: {
    fontWeight: "bold",
  },
  Analyse: {
    fontSize: "14px",
  },
  Information: {
    padding: "5%",
    fontSize: "14px",
  },
  SankeyDates: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
    "& a": {
      fontSize: "10px",
    },
  },
  SankeyStartDate: {
    left: "0px",
    fontSize: "14px",
  },
  editPageCard: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  },
  pageCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  },
  fullPageCard: {
    width: "100%",
  },
  pageTitleContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  pageTitle: {
    paddingBottom: "8px",
    paddingLeft: "52px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      marginLeft: "10px",
    },
    "& .MuiTypography-root": {
      fontWeight: "600",
      fontFamily: "Montserrat",
      fontSize: "40px",
    },
  },
  pageSubTitle: {
    paddingLeft: "52px",
    "& .MuiTypography-root": {
      color: "#5F6971 !important",
    },
  },
  pageContent: {
    padding: "0px 0px 0px 36px",
    marginRight: "-16px",
    maxWidth: "-webkit-fill-available",
  },
  pageLoadingList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "400px",
  },
  bottomButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: "16px",
    marginLeft: "36px",
    width: "100%",
    "& button": {
      marginRight: "16px",
    },
    position: "absolute",
    bottom: "25px",
  },
  pageButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: "16px",
    "& button": {
      marginRight: "16px",
    },
  },
  syncExcel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    // width: "100%",
    // "& button": {
    //   marginRight: "16px",
    // },
  },
  dropzoneContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .MuiDropzoneArea-root": {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      border: "1px dashed #A6ACAF",
      borderRadius: "8px",
      backgroundColor: "#F5F5F5",
      marginBottom: "10px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        fontFamily: "Open Sans",
      },
      "& .MuiDropzoneArea-icon": {
        color: "#71B9A1",
      },
      "& .MuiDropzonePreviewList-image": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },
    },
  },
  syncLineContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "12px 0px",
    "& .MuiTypography-root": {
      alignContent: "center",
    },
    "& .MuiSwitch-root": {
      margin: "0px",
    },
    "& .MuiFormControlLabel-root": {
      margin: "0px",
    },
  },
  cancelButton: {
    backgroundColor: "#D95F48 !important",
    height: "40px",
    borderRadius: "32px !important",
    color: "white !important",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    // padding: "16px 16px",
    "& .MuiTypography-root": {
      fontSize: "14px !important",
    },
    "&.Mui-disabled": {
      "&.MuiButtonBase-root": {
        pointerEvents: "auto !important",
      },
    },
  },
  bottomButtonBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    "& button": {
      marginLeft: "24px",
    },
    "& .MuiTypography-root": {
      fontWeight: "600",
      fontSize: "14px",
      fontFamily: "Montserrat",
      padding: "4px 16px",
    },
    "& .MuiButtonBase-root": {
      fontFamily: "Montserrat",
    },
  },
  bottomButtonPage: {
    marginTop: "32px",
  },
  grid: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "stretch",
    "& .MuiGrid2-root": {
      height: "328px !important",
    },
  },
  NoData: {
    placeItems: "center",
    display: "flex",
    opacity: 0.4,
    height: "100%",
    flexDirection: "column",
    placeContent: "center",
    border: "1px solid #A6ACAF",
    "& .MuiSvgIcon-root": {
      width: "150px",
      height: "150px",
    },
    "& .MuiTypography-root": {
      textAlign: "center",
    },
  },
  pageComponent: {
    margin: "0px 0px 0px 52px",
    "& .RaShow-card": {
      borderRadius: "8px",
    },
  },
  headerTableInactive: {
    "& .RaDatagrid-headerCell": {
      pointerEvents: "none",
      "& .MuiTableSortLabel-icon": {
        opacity: "0",
      },
    },
  },
  listActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: "65px",
  },
  listButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  tooltip: {
    "& .MuiTooltip-tooltip": {
      backgroundColor: "white",
      boxShadow: "0px 0px 6px rgba(55, 66, 74, 0.6)",
      color: "#1B232A",
      fontFamily: "Open Sans",
      fontSize: "10px",
      fontWeight: "400",
      maxWidth: "150px",
      textAlign: "center",
    },
  },
  tooltipIcon: {
    padding: "0px !important",
    backgroundColor: "transparent !important",
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
  customButton: {
    height: "40px",
    backgroundColor: "#71B9A1 !important",
    borderRadius: "32px",
    color: "white !important",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    padding: "16px 32px",
    "& .MuiTypography-root": {
      fontSize: "14px !important",
      fontWeight: "600",
      fontFamily: "Montserrat",
    },
    "&.Mui-disabled": {
      "&.MuiButtonBase-root": {
        pointerEvents: "auto !important",
      },
    },
  },
  iconButton: {
    color: "#71B9A1 !important",
  },
  cardCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  chartDPE: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    paddingTop: "20px",
  },
  dpeButton: {
    "&:hover": {
      cursor: "default",
    },
    backgroundColor: "#71B9A1",
    borderRadius: "32px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "16px 32px",
    "& .MuiTypography-root": {
      fontSize: "14px !important",
      textAlign: "center",
    },
  },
  developmentPage: {
    marginLeft: "52px",
    height: "calc(100vh - 180px)",
    position: "relative",
  },
  developmentText: {
    position: "absolute",
    top: "calc(50% - 180px)",
    left: "0",
    zIndex: "1",
    "@media (max-width: 1700px)": {
      maxWidth: "350px !important",
    },
    "@media (max-width: 1300px)": {
      position: "relative",
    },
  },
  developmentTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
  },
  developmentParagraph: {
    paddingTop: "27px",
    paddingBottom: "27px",
    lineHeight: "19.5px",
  },
  developmentButton: {
    "& .MuiTypography-root": {
      fontSize: "18px !important",
    },
  },
  developmentImage: {
    position: "absolute",
    top: "calc(50% - 214px)",
    right: "0",
    "@media (max-width: 1300px)": {
      position: "relative",
    },
  },
  developmentImg: {
    "@media (max-width: 1700px)": {
      marginTop: "76px !important",
      width: "600px !important",
    },
    "@media (max-width: 1400px)": {
      width: "450px !important",
    },
    "@media (max-width: 1300px)": {
      width: "350px !important",
    },
  },
  composition: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: "45px",
  },
  compositionBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "50%",
  },
  compositionNumber: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  compositionText: {
    textAlign: "center",
    fontWeight: "bold !important",
  },
  missingBillsNumbers: {
    display: "flex",
    alignItems: "center",
    "& div": {
      marginRight: "30px",
    },
  },
  missingBillsNumbersElement: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  missingBillsText: {
    marginLeft: "10px",
  },
  DetailledFullNoData: {
    height: "90%",
  },
  pagination: {
    alignSelf: "center",
  },
  analysisAccordion: {
    boxShadow: "none !important",
    "& .MuiAccordionSummary-root": {
      padding: "0px !important",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      color: "#71B9A1 !important",
    },
    "& .MuiAccordionDetails-root": {
      border: "1px solid rgba(166, 172, 175, 0.20)",
      padding: "16px 20px",
      fontSize: "12px !important",
    },
  },
  analysisAccordionLoading: {
    "& .MuiAccordionDetails-root": {
      border: "1px solid #71B9A1",
    },
  },
  historicCard: {
    paddingTop: "64px",
  },
  QualityDataGrid: {
    height: "300px",
    width: "100%",
  },
  dataGridQualityStatsReasoned: {
    backgroundColor: getBackgroundColor(theme.palette.aegeon.success, theme.palette.mode) + " !important",
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(theme.palette.aegeon.success, theme.palette.mode) + " !important",
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(theme.palette.aegeon.success, theme.palette.mode) + " !important",
      "&:hover": {
        backgroundColor:
          getSelectedHoverBackgroundColor(theme.palette.aegeon.success, theme.palette.mode) + " !important",
      },
    },
  },
  dataGridQualityStatsOutdated: {
    backgroundColor: getBackgroundColor(theme.palette.aegeon.warning, theme.palette.mode) + " !important",
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(theme.palette.aegeon.warning, theme.palette.mode) + " !important",
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(theme.palette.aegeon.warning, theme.palette.mode) + " !important",
      "&:hover": {
        backgroundColor:
          getSelectedHoverBackgroundColor(theme.palette.aegeon.warning, theme.palette.mode) + " !important",
      },
    },
  },
  dataGridQualityStatsActual: {
    backgroundColor: getBackgroundColor(theme.palette.aegeon.info, theme.palette.mode) + " !important",
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(theme.palette.aegeon.info, theme.palette.mode) + " !important",
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(theme.palette.aegeon.info, theme.palette.mode) + " !important",
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(theme.palette.aegeon.info, theme.palette.mode) + " !important",
      },
    },
  },
  userPageContent: {
    padding: "16px 16px 16px 52px",
  },
  pageMargin: {
    marginTop: "1em",
  },
  UserGeneralInformation: {
    display: "flex",
    flexDirection: "column",
    "& .MuiFormControlLabel-root": {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      marginLeft: "0px",
    },
  },
  UserField: {
    marginBottom: "14px",
  },
  userListPerimeter: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    marginBottom: "0px",
  },
  customChip: {
    "& .MuiChip-root": {
      color: "#37424A !important",
      fontFamily: "Open Sans !important",
    },
  },
  removedUserChip: {
    backgroundColor: "#D95F4870 !important",
    color: "#37424A !important",
  },
  addedUserChip: {
    backgroundColor: "rgba(92, 255, 189, 0.5) !important",
  },
  sameUserChip: {
    backgroundColor: "rgba(0, 0, 0, 0.08) !important",
  },
  usersSyncTable: {
    maxHeight: "calc(100vh - 350px)",
    borderRadius: "8px",
  },
  newUserRow: {
    backgroundColor: "rgba(92, 255, 189, 0.3)",
  },
  changedUserRow: {
    backgroundColor: "#80C3FF20",
  },
  changedUserTableCell: {
    backgroundColor: "rgba(92, 255, 189, 0.1)",
  },
  oldValueTooltip: {},
  subdevicesTimeseriesCell: {
    width: "60%",
    height: "100px",
  },
  userPerimeterPeriods: {
    display: "flex",
    width: "100%",
  },
  timeConstraintPerimeter: {
    width: "100%",
    "& .RaSimpleFormIterator-line": {
      marginBottom: "16px",
      backgroundColor: "#71B9A115",
      borderRadius: "8px",
      padding: "7px",
    },
    fullwidth: {
      width: "100%",
    },
  },
  devicesListBuildingList: {
    maxWidth: "300px",
  },
  datePickerContainer:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    height: "30px",
  },
  datePicker:{
    fontSize:"12px",
    fontFamily: 'Open Sans',
    color: "#5F6971",
    padding: "5px",
    border: "0.5px solid #2e2e2e33 !important",
    borderRadius: "15px",
    outline: "none",
    backgroundColor: "white !important",
  },
  datePickerIcon:{
    color: "#71B9A1",
  },
  datePickerCalendar:{
    fontSize:"10px",
    fontFamily: 'Open Sans',
    borderRadius: "15px",
    border: "0.5px solid #2e2e2e33 !important",
    "& .react-datepicker__header": {
      borderRadius: "15px 15px 0 0",
      backgroundColor: "#ebe9e450",
    },
    "& h2": {
      color: "#37424A",
      fontFamily: 'Open Sans',
      fontSize:"14px",
    },
    "& .react-datepicker__day--selected": {
      backgroundColor: "#71B9A1 !important",
      color: "white",
    },
    "& .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range": {
      backgroundColor: "#71B9A1 !important",
      color: "white",
    },
    "& .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover": {
      backgroundColor: "#71B9A1 !important",
      color: "white",
    },
    "& .react-datepicker__day--keyboard-selected": {
      backgroundColor: "#71B9A180 !important",
      color: "white",
    },
    "& .react-datepicker__month-text--in-range": {
      backgroundColor: "#71B9A1 !important",
      color: "white",
    },
    "& .react-datepicker__month-text--keyboard-selected": {
      backgroundColor: "#71B9A180 !important",
      color: "white",
    },
    "& .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range": {
      backgroundColor: "#71B9A1 !important",
      color: "white",
    },
    "& .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover": {
      backgroundColor: "#71B9A1 !important",
      color: "white",
    },
  },
  dateTooltip: {
    "& .MuiTooltip-tooltip": {
      backgroundColor: "white",
      boxShadow: "0px 0px 6px rgba(55, 66, 74, 0.6)",
      color: "#1B232A",
      fontFamily: "Open Sans",
      fontSize: "10px",
      fontWeight: "400",
      textAlign: "center",
    },
  },
  evolutionButtons: {
    display: "flex",
    flexDirection: "row",
    "& .MuiToggleButtonGroup-root": {
      height: "40px",
    },
    marginBottom: "20px",
  },
  evolutionPeriods:{
    display: "flex",
    flexDirection: "row",
  },
  evolutionPeriodButtons: {
    display: "flex",
    marginRight: "60px",
  },
  calendarPeriods: {
    display: "flex",
    flexDirection: "column",
    marginRight: "60px",
  },
  evolutionMissingBills: {
    marginTop: "40px",
  },
}));

export default globalUseStyles;
