const translateSitesFr = {
  sites: {
    title: "Bâtiment ",
    page_subtitle: " de Qualité de Synoptique ",
    no_data_tooltip: "Cette donnée n'est pas renseignée",
    list: {
      name: "Nom du Bâtiment",
      code: "Code Bâtiment",
    },
    exports: {
      heatmap: "AEGEON_heatmap_",
      line: "AEGEON_courbe_de_charge_",
      monotone: "AEGEON_monotone_",
      table: "AEGEON_series_",
    },
    sources: {
      general_information:"Données du bâtiment",
      synoptic_serie: "Synoptique",
      ef_serie: "Entités fonctionnelles",
      fusioned_energy: "Conso AEGEON",
      devices_fusioned_energy: "PDL Série AEGEON",
      billed_energy:"PDL DM",
      manual_bills: "PDL Analogue",
      third_party_energy: "PDL DM Tiers",
      fusioned_energy_for_devices: "PDL Série AEGEON",
      synoptic:{
        code: "Numéro de compteur",
        fluid: "Type de fluide",
        destination: "Parties alimentées",
        quality: "Qualité de la donnée",
        usage: "Usage",
        tenants: "Locataires en date du dernier état locatif",
        automatic_upload: "Type de remontée des données",
        upload: "Possibilités de remontée énergétique",
        tenth: "Tantièmes",
        questions:"Questions relatives au PDL",
        meter_type:"Type de compteur",
      },
      ten_years_serie: "Données 10 ans",
      ef:{
        code: "Code",
        lots: "Lots",
        surface: "Surface",
        tenant: "Locataire",
        tenant_code: "Code locataire",
        activity: "Activité",
      },
      devices:{
        code: "PDL",
        fluid: "Type de fluide",
        last_tarif_change: "Date du dernier changement tarifaire",
        contract_state: "État contractuel",
        deposited: "Compteur déposé",
        start_date: "Date de début de donnée",
        end_date: "Date de fin de donnée",
        quality: "Qualité de la donnée",
        unit: "Unité",
        last_analog_invoice_date: "Date de la dernière facture analogue",
        analog_periods_without_data: "Périodes sans données",
      },
    },
    sort_button: {
      sort_by_default: "RÉINITIALISER LE TRI",
      sort_by_default_tooltip: "Le tri par défaut correspond à un tri par qualité de synoptique puis par code.",
    },
    general_information: {
      title: "Informations générales",
      subtitle: "Quelles sont les informations disponibles pour ce bâtiment ?",
      tooltip_text:
        "Cette tuile vous donne une foultitude raisonnée des informations nécessaires à la bonne connaissance de votre bâtiment, de sa consommation et de son usage.",
      no_data_text: "",
      name: "Nom du bâtiment",
      name_no_data:
        "Le nom du bâtiment nous sert autant dans les tris que dans la bonne présentation du bâtiment, il serait dommage que nous ne le connaissions pas.",
      code: "Code bâtiment",
      code_no_data:
        "Le code bâtiment sert à identifier plus facilement l'actif que vous souhaitez consulter, il serait dommage que nous ne le connaissions pas.",
      address: "Adresse",
      address_no_data:
        "L'adresse nous sert, outre le fait de localiser le bien, à identifier et/ou vérifier le synoptique d'alimentation de votre actif. En cas d'adresses multiples, il est utile de tenir compte de toutes.",
      address_link_tooltip: "Voir sur Google Maps",
      organizations: "Propriétaire",
      organizations_no_data:
        "Le propriétaire de l'actif nous sert à la segmentation des analyses de niveau \"patrimonial\". En cas d'indivision et ou de règles de gestion spécifiques, il convient de nous le faire savoir",
      declared_detention_regime: "Régime de détention",
      declared_detention_regime_no_data:
        "Le régime de détention du bien permet de faciliter l'identification de \"tiers portant des fluides (Syndic. de copropriété, gestionnaire d'AFUL, d'ASL, espaces communs)\" et la prise en compte des consommations associées dans vos reportings.",
      construction_date: "Date de construction",
      construction_date_no_data:
        "La date de constrution de l'actif est un bon axe d'analyse pour les patrimoines immobiliers. La date du permis de construire renseigne sur la règlementation thermique applicable au bâtiment, la date de réception le point de départ des garanties. Une manière certaine de la récupérer ? Consultez votre acte de vente.",
      acquisition_date: "Date d'acquisition",
      acquisition_date_no_data:
        "La date d'acquisition permet de déterminer le démarrage de la responsabilité du suivi et de la gestion énergétique de l'actif. Il est important pour nos analyses que nous la connaissions.",
      last_renovation_date: "Date de dernière rénovation",
      last_renovation_date_no_data: "Date de dernière rénovation non renseignée",
      functional_entities_state_date: "Date du dernier état locatif connu",
      functional_entities_state_date_no_data:
        "La date du dernier état locatif connu vous renseigne sur la date de mise à jour et ou de vérification du synoptique de l'actif.",
      validated_client_surface: "Surface utile",
      validated_client_surface_no_data: "Information indisponible ou validation de la surface non reçue.",
      number_of_buildings: "Nombre de bâtiments",
      number_of_buildings_no_data: "Nombre de bâtiments non renseigné",
      tiers: "Présence d'un tiers porteur de fluide",
      tiers_no_data:
        "Ce type de tiers (Syndic de copropriété, gestionnaire d'AFUL, d'ASL, d'espaces communs) porte souvent un ou plusieurs points de consommations en énergie ou en eau. Bien les identifier permet de fiabiliser votre connaissance de la réelle consommation de l'actif.",
      declared_rental_typology: "Typologie locative déclarée",
      number_of_potentially_subjected_functional_entities: "Nombre d'EF potentiellement assujetties",
      number_of_potentially_subjected_functional_entities_no_data:
        "Le nombre d'entités assujetties permet de qualifier la granulométrie d'usage du bâtiment. C'est un facteur important de nos analyses issues du traitement des états d'occupations fournies.",
      number_of_tenants_following_rental_state_ex_parking: "Nombre de locataires",
      number_of_tenants_following_rental_state_ex_parking_no_data: "Nombre de locataires non renseigné",
      activities: "Activité des locataires",
      activities_no_data:
        "Le type d'usage des entités fonctionnelles est issu de l'analyse des états d'occupation des bâtiments. Ils renseignent sur le contexte de l'actif et peuvent être pris en compte dans les analyses.",

      period_of_reporting: "Période de reporting",
      period_of_reporting_no_data:
        "Période du reporting annuel du client. Base de nombreux chiffres présentés sur la plateforme, la période de reporting est considérée par défaut comme courant du 01/01/20XY au 31/12/20XY)",
      reporting_type: "Type de reporting",
      reporting_type_no_data:
        "Le type de reporting permet à la plateforme de vous proposer des exports conformes à vos besoins en reporting.",
      synoptic_quality: "Qualité du synoptique",
      synoptic_quality_2023: "Qualité du dernier synoptique (2023)",
      synoptic_quality_no_data: "Qualité du synoptique non renseignée",
      energy_quality_data: "Qualité des données de chaque compteur reportées au dernier reporting",
      energy_quality_data_no_data:
        "Qualité des données de chaque compteur reportées au dernier reporting non renseignée",
      deet_subject: "Actif soumis au Dispositif Eco Energie Tertiaire",
      deet_subject_tooltip_info: "Information indicative basée sur la surface connue de l'actif, ne tient pas compte de l'inclusion dans une entité foncière, ni des exclusions induites par l'activité du ou des locataires.",
      deet_subject_no_data:
        "Si un actif est soumis au dispositif éco énergie tertiaire, il est nécessaire d'en reporter annuellement les consommations sur le site OPERAT de l'ADEME et d'atteindre une performance cible en 2030, 2040, et 2050.",
      last_reporting_period_surface_consumption: "Performance surfacique de l'avant dernier reporting officiel (2021)",
      last_reporting_period_surface_consumption_no_data:
        "Malheureusement aucune performance de référence n'est connue pour cette période.",
      reporting_2023_surface_consumption: "Performance surfacique du dernier reporting officiel (2022)",
      reporting_2023_surface_consumption_no_data:
        "Malheureusement aucune performance de référence n'est connue pour cette période.",
      reporting_2023_total_consumption: "Consommation totale 2023",
      reporting_2023_total_consumption_no_data: "Malheureusement aucune consommation n'est connue pour cette période.",
      reporting_2023_total_ghg_emissions: "Émissions GES 2023",
      reporting_2023_total_ghg_emissions_no_data: "Malheureusement aucune émission n'est connue pour cette période.",
      reporting_2023_total_water_consumption: "Consommation d'eau 2023",
      reporting_2023_total_water_consumption_no_data:
        "Malheureusement aucune consommation n'est connue pour cette période.",
      final_energy_perf_bpe_oid_ref: "Performance énergétique - Référence OID 2023",
      final_energy_perf_bpe_oid_ref_no_data: "Aucune donnée connue pour cette période.",
      co2_perf_bpe_oid_ref: "Performance climat - Référence OID 2023",
      co2_perf_bpe_oid_ref_no_data: "Aucune donnée connue pour cette période.",
      water_perf_bpe_oid_ref: "Performance eau - Référence OID 2023",
      water_perf_bpe_oid_ref_no_data: "Aucune donnée connue pour cette période.",
      fluid: "Fluides énergétiques alimentant le bâtiment",
      fluid_no_data:
        "La connaissance de l'ensemble des fluides d'un actif permet d'améliorer la compréhension de son profil de consommation et de s'assurer de la complétude du reporting.",
      last_dpe_date: "Date du dernier DPE",
      last_dpe_date_no_data:
        "Cette date nous permet de vous alerter dans le cas de changement important des DPE estimés par nos algorithmes.",
      last_dpe_rate: "Notation du dernier DPE",
      last_dpe_rate_no_data:
        "Cette note historique nous sert de référence pour vous alerter en cas de changement important des DPE estimés par nos algorithmes.",
      last_dpe_rate_emissions: "Émissions : ",
      last_dpe_rate_energy: "Énergie : ",
      construction_certification: "Certification de construction",
      construction_certification_no_data: "Certification de construction non renseignée",
      exploitation_certification: "Certification d'exploitation",
      exploitation_certification_no_data: "Certification d'exploitation non renseignée",
      thermal_regulation: "Réglementation thermique",
      thermal_regulation_no_data: "Réglementation thermique non renseignée",
      biodiversity_label: "Label biodiversité",
      biodiversity_label_no_data: "Label biodiversité non renseigné",
      aegeon_energy_data_type: "Type de remontée des données",
      aegeon_energy_data_type_no_data: "Type de remontée des données non renseignée",
      aegeon_energy_data_types: {
        mixed: "Mixte",
      },
      deet_geographic_zone: "Zone climatique",
      deet_geographic_zone_no_data: "Zone climatique non renseignée",
      altitude: "Altitude",
      altitude_no_data: "Altitude non renseignée",
      reference_weather_station_city: "Station météo de référence",
      reference_weather_station_city_no_data: "Station météo de référence non renseignée",
      irves_presence: "Présence d'une installation de recharge pour véhicules électriques",
      irves_presence_no_data:
        "Ces installations sont amenées à se développer et sont de potentielles grosses consommatrices. Leur suivi spécifique est nécessaire pour en permettre le décompte lors de la déclaration OPERAT de l'actif.",
      autoproduction_presence: "Présence d'une centrale de production en autoconsommation",
      autoproduction_presence_no_data:
        "Ces installations sont amenées à se développer et participent potentiellement à la performance de l'actif. En connaître les caractéristiques permet d'en tenir compte dans l'analyse des consommations de l'actif.",
      gtb_presence: "Présence d'un système de gestion technique du bâtiment",
      gtb_presence_no_data:
        "Ces installations sont amenées à se développer et participent potentiellement à la performance de l'actif. Bien utilisées et associées à la connaissance fine des appels de puissance et des usages de l'actif, elles peuvent permettre des économies d'énergies de plus de 40%.",
      rie_presence: "Présence d'un RIE",
      rie_presence_no_data: "Présence d'un RIE non renseignée",
      number_of_outdoor_parkings: "Nombre de places de parking extérieur",
      number_of_outdoor_parkings_no_data:
        "La loi du 10 mars 2023 relative à l'accelération de la production d'énergies renouvelables rend obligatoire le déploiement de moyen de production d'énergie solaire sur les parkings sous critère de taille. Connaître le nombre de place de parking donne une bonne indication des actifs soumis à ces dispositions.",
      number_of_indoor_parkings: "Nombre de places de parking intérieur",
      number_of_indoor_parkings_no_data:
        "La loi LOM stipule que tous les parkings de bâtiments non résidentiels comptant 11 places et plus doivent être équipés de point de charge à hauteur de 5% des places de stationnement. Connaître le nombre de places de parking nous permet de qualifier les objectifs associés à cette obligation règlementaire.",

      opening_hours: "Horaires d'ouverture",
      opening_hours_no_data:
        "Ces horaires n'ont pas été validées par le client. Elles permettent de qualifier le potentiel d'amélioration de la performance énergétique du bâtiment.",
      working_days: "Jours ouvrés",
      working_days_no_data:
        "Ces jours n'ont pas été validés par le client. Ils permettent de qualifier le potentiel d'amélioration de la performance énergétique du bâtiment.",
      current_typology: "Typologie retenue",
      current_typology_no_data:
        "La typologie d'usage retenue pour l'actif permet des analyses segmentées selon ce critère et l'identification de performance de référence comparable. Plus une segmentation est précise plus elle est pertinente.",
      contact_no_data: "Information indisponible",
      technical_contact_name: "Intervenant technique interne",
      technical_assistant_contact_name: "Assistant intervenant technique",
      billing_contact_name:"Interlocuteur facturation",
      asset_manager_contact_name:"Asset Manager",
      rental_manager_contact_name:"Gestionnaire locatif",
      maintainers_intervenants_contact_name:"Intervenant mainteneur",
      property_manager_contact_name:"Property Manager",
      property_manager_agency_name:"Agence Property Manager",
      perimeter_2023: "Périmètre 2023",
      perimeter_2023_no_data: "Périmètre 2023 non renseigné",
      left_panel: {
        title: "Données patrimoniales et locatives",
      },
      right_panel: {
        title: "Données techniques",
      },
      left_bottom_panel: {
        title: "Données du dernier reporting énergétique",
        tooltip: "Les consommations sont reportées selon les méthodes DEET.",
      },
      right_bottom_panel: {
        title: "Usages et gestion",
      },
      fluids: {
        EAU: "Eau",
        GAZ: "Gaz",
        ELEC: "Électricité",
        HEAT: "Réseau de chaleur urbain",
        COLD: "Réseau de froid urbain",
        ERREUR: "Erreur",
        FOD: "Fioul domestique",
        PROPANE: "Propane",
        WOOD: "Bois",
        BUTANE: "Butane",
        COAL: "Charbon",
        "OTHER FOSSIL FUEL": "Autre combustible fossile",
        "NON CONNU A DATE": "Non connu à date",
        null: '',
        undefined: '',
      },
    },
    energetic_performance: {
      title: "Performance énergétique",
      subtitle: "Quelle étiquette de performance énergétique correspond à ce bâtiment ",
      tooltip_text:
        "Étiquette AEGEON sur base des facteurs de conversion DEET. Le calcul est réalisé grâce aux données de la série AEGEON et de surface du bâtiment.",
      bad_quality_tooltip:
        "Certains de vos compteurs énergétiques ont une qualité dégradée et impactent positivement la performance présentée ici. Nous travaillons à la fiabiliser.",
      subtitle_dates_1: "pour les données du ",
      subtitle_dates_2: " au ",
      subtitle_dates_3: " ?",
      subtitle_airtable_value: "pour les données du dernier reporting ?",
      no_data: "Nous n'avons pas assez de données disponibles pour effectuer ce calcul.",
      dpe: {
        title: "Performance énergétique AEGEON",
        subtitle: "Quelle est la performance énergétique de ce bâtiment ",
        tooltip_text:
          "Étiquette AEGEON sur base des facteurs de conversion DEET. Le calcul est réalisé grâce aux données de consommation et de surface du bâtiment.",
        bad_quality_tooltip:
          "Certains de vos compteurs énergétiques ont une qualité dégradée et impactent positivement la performance présentée ici. Nous travaillons à la fiabiliser.",
      },
      climat: {
        title: "Performance climat AEGEON",
        subtitle: "Quelle est la performance climat de ce bâtiment ",
        tooltip_text:
          "Étiquette AEGEON sur base des facteurs de conversion DEET. Le calcul est réalisé grâce aux données de consommation et de surface du bâtiment.",
        bad_quality_tooltip:
          "Certains de vos compteurs énergétiques ont une qualité dégradée et impactent positivement la performance présentée ici. Nous travaillons à la fiabiliser.",
      },
    },
    evolution: {
      title: "Évolution des consommations",
      subtitle: "Comment la consommation a-t-elle évolué ",
      tooltip_text_source: "Nous utilisons ici l'agrégration des données de la Série AEGEON. ",
      tooltip_warning_1:
        "Attention, le nombre de jours de données de l'année dernière et de cette année est différent : ",
      tooltip_warning_2: " jours l'année dernière contre ",
      tooltip_warning_3: " jours cette année. Le calcul de la variation de consommation est donc peu fiable.",
      tooltip_text_1: "La comparaison est faite sur ",
      tooltip_text_2: " mois.",
      no_data: "Pas assez de données disponibles pour effectuer cette comparaison.",
      tooltip_no_data: "Pas assez de données disponibles pour effectuer cette comparaison.",
      subtitle_dates_1: "du ",
      subtitle_dates_2: " au ",
      subtitle_dates_3: " de la période en cours et de la précédente ?",
      subtitle_red:
        " Certains de vos compteurs énergétiques ont une qualité dégradée et impactent la comparaison présentée ici.",
      subtitle_orange:
        " Certains de vos compteurs énergétiques ont une qualité moyenne et impactent la comparaison présentée ici.",
      subtitle_green: " Tous vos compteurs énergétiques ont une très bonne qualité.",
    },
    histogram: {
      title: "Consommations mensuelles",
      subtitle: "Quelles sont les consommations mensuelles de ce bâtiment agrégées par destination et fluide ?",
      dj: "Degrés-jours",
      djr: "DJR",
      dju: "DJU",
      meteo_station_tooltip: "La station météo utilisée ici est ",
      tooltip_text: "Nous utilisons ici l'agrégration des données de la Série AEGEON. Les DJU/DJR proviennent de Météo France. ",
      tooltip_red:
        "Certains de vos compteurs énergétiques ont une qualité dégradée et impactent la fiabilité des données présentées ici.",
      tooltip_orange:
        "Certains de vos compteurs énergétiques ont une qualité moyenne et impactent la fiabilité des données présentées ici.",
      tooltip_green:
        "Tous vos compteurs énergétiques ont une très bonne qualité ce qui garantit la fiabilité des données présentées ici.",
      aggDestination: {
        title: "Consommations mensuelles agrégées par destination",
      },
      aggFluid: {
        title: "Consommations mensuelles agrégées par fluide",
      },
      aggFluidDestination: {
        title: "Consommations mensuelles agrégées par destination et fluide",
      },
    },
    table: {
      title: "Tableaux des consommations",
      subtitle: "Quelles sont les détails des consommations de ce bâtiment ? ",
      tooltip_text: "Différencié par destination et par fluide.",
      total: {
        title: "Somme des Données du Bâtiment",
        tooltip: "Ces données sont calculées en faisant la somme de la série AEGEON de chaque compteur, multipliée par son facteur de conversion ENEF PCI DEET ainsi que par le tantième du compteur.",
      },
      fluid_sources: {
        electricity: "Source du facteur de conversion Électricité (1 kWhef PCS = 1 kWhef PCI) : Arrêté du 24 novembre 2020 (dernière Mise à jour : 06/11/2023)",
        gaz: "Source du facteur de conversion Gaz (1 kWhef PCS = 0,9 kWhef PCI) : Arrêté du 10 avril 2020 (dernière Mise à jour : 06/11/2023)",
        fod: "Source du facteur de conversion Fioul (1 litre = 9,97 kWh PCI) : Arrêté du 10 avril 2020 (dernière Mise à jour : 06/11/2023)",
        heat: "Source du facteur de conversion Réseau Chaud (1 kWhef PCS = 0,77 kWhef PCI) : Arrêté du 13 avril 2022 (dernière Mise à jour : 06/11/2023)",
        cold: "Source du facteur de conversion Réseau Froid (1 kWhef PCS = 0,25 kWhef PCI) : Arrêté du 13 avril 2022 (dernière Mise à jour : 06/11/2023)",
      },
      dju: {
        title: "DJU",
        tooltip: "Les données météorologiques proviennent de la station météo de référence la plus proche.",
        source: "Source : Météo France (dernière Mise à jour : 11/12/2024)",
      },
      djr: {
        title: "DJR",
        tooltip: "Les données météorologiques proviennent de la station météo de référence la plus proche.",
        source: "Source : Météo France (dernière Mise à jour : 11/12/2024)",
      },
      occupation_rate: {
        title: "Taux d'occupation",
        subtitle: "Le taux d'occupation est exprimé en pourcentage.",
        tooltip: "Le taux d'occupation permet de comprendre les évolutions de consommation en fonction de l'occupation du bâtiment.",
      },
      devices: {
        title: "Consommation par compteur sur la dernière année",
        subtitle: "Les consommations totales de la série AEGEON des points associés au bâtiment sur les 12 derniers mois.",
        tooltip: "Attention, ici ce sont les consommations totales avant multiplication par le facteur de conversion et le tantième.",
        subtitle_no_data: "Aucune donnée disponible pour cette période.",
        name: "Numéro",
        fluid: "Fluide",
        destination: "Parties alimentées",
        unit: "Unité",
        quality: "Qualité",
        tenth: "Tantièmes",
      },
    },
    analyse: {
      title: "Analyse des consommations",
      subtitle: "Comment interpréter ces données ?",
      tooltip_text: "Analyse réalisée sur les données de consommations, techniques, patrimoniales et d'usage du bâtiment.",
    },
    heatmap: {
      title: "Heatmap",
      subtitle: "Comment la consommation de ce bâtiment est-elle répartie sur la semaine pour les compteurs électriques ?",
      tooltip_text:
        "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      missing_values: "Données manquantes ou estimées",
      details: {
        title: "Heatmaps",
        weekly_heatmap: "Heatmap par jour de la semaine",
        last_month_heatmap: "Heatmap du dernier mois",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
      day: {
        title: "Heatmap par jour de la semaine",
        subtitle: "Moyenne par jour de la semaine sur le dernier mois",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
      month: {
        title: "Heatmap du dernier mois",
        subtitle: "Heatmap du dernier mois",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
      last_winter: {
        title: "Heatmap de l'hiver dernier",
        subtitle: "Heatmap d'un mois type sur l'hiver dernier",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
      last_summer: {
        title: "Heatmap de l'été dernier",
        subtitle: "Heatmap d'un mois type sur l'été dernier",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
      last_autumn: {
        title: "Heatmap de l'automne dernier",
        subtitle: "Heatmap d'un mois type sur l'automne dernier",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
      last_spring: {
        title: "Heatmap du printemps dernier",
        subtitle: "Heatmap d'un mois type sur le printemps dernier",
        tooltip_text:
          "Nous utilisons ici les données détaillées. Nous calculons avec un taux de confiance de 95%. Si plus de 5% de la valeur est estimée ou corrigée, elle sera affichée en gris.",
      },
    },
    missing_information: {
      title: "Données manquantes",
      subtitle: "Quelles sont les données manquantes prioritaires et possibles pour ce bâtiment ?",
      tooltip_text: "Ajouter ces données vous permettra d'avoir un meilleur synoptique.",
      no_missing_info: "Aucune donnée manquante",
      pdl_without_data_quality: "Compteurs en service sans qualité de données",
      difference_surface: "Différence surface validée client / surface totale EF",
      no_missing_autorisation: "Aucune autorisation manquante",
      no_bills:
        "Non relevant pré collecte des factures annuelles, elles manquent toutes pour l'année de reporting courante",
      nb_pdl_bad_quality: "Nombre de compteurs dont la donnée est à fiabiliser",
      nb_pdl_not_dest: "Nombre de compteurs dont les parties alimentées sont inconnues",
      nb_pdl_shared_not_usage: "Nombre de compteurs dont l'usage est inconnu (parties communes)",
      nb_pdl_no_authorisation: "Nombre de compteurs sans autorisation",
      pdl_bad_quality: "Compteurs dont la donnée est à fiabiliser (Qualité de données C)",
      pdl_with_passed_authorization: "Compteurs dont la date de fin d'autorisation est dépassée",
      pdl_without_destination: "Compteurs dont les parties alimentées sont inconnues",
      pdl_shared_without_usage: "Compteurs desservant des parties communes dont l'usage est inconnu",
      nb_missing_client_information: "Manque de données client",
      missing_bills_percentage_energetic: "Pourcentage de mois avec données manquantes pour les compteurs énergétiques",
      missing_bills_percentage_water:"Pourcentage de mois avec données manquantes pour l'eau",
      missing_bills_percentage_tooltip: "Fournir les factures manquantes permettra de pouvoir proposer des données complètes sur l'intégralité du bâtiment. Vous pouvez combler ces trous en envoyant les factures à votre Energy Manager et en signant les mandats pour les compteurs Automatiques.",
      left_panel: {
        title: "Manque de données client",
        tooltip:
          "Les données manquantes qui peuvent être renseignées par le client afin d'avoir un meilleur synoptique.",
      },
      right_panel: {
        title: "Manque de données Energy Manager",
        tooltip:
          "Les données manquantes qui peuvent être renseignées par l'Energy Manager afin d'avoir un meilleur suivi.",
      },
      left_bottom_panel: {
        title: "Manque d'autorisation",
        tooltip:
          "Fournir les autorisations manquantes permettra de pouvoir remonter automatiquement les données de consommation de l'intégralité du bâtiment.",
      },
      right_bottom_panel: {
        title: "Manque de factures du dernier reporting",
        subtitle: "sur la période du ",
        tooltip:
          "Fournir les factures manquantes permettra de pouvoir proposer des données complètes sur l'intégralité du bâtiment. Vous pouvez combler ces trous en envoyant les factures à votre Energy Manager et en signant les mandats pour les compteurs Automatiques.",
        no_missing_bills: "Aucune donnée de consommation manquante sur l'ensemble des compteurs",
        percentage_text_1: "de mois",
        percentage_text_2: "incomplets",
        percentage_title_energetic: "Energétique",
        percentage_title_water: "Eau",
        months_text: "mois",
        table_title_energetic: "Compteurs énergétiques",
        table_title_water: "Compteurs d'eau",
      },
    },
    composition: {
      title: "Entités fonctionnelles et compteurs",
      subtitle: "De quoi est composé ce bâtiment ?",
      tooltip_text: "Cette tuile vous donne une vue d'ensemble des entités fonctionnelles et compteurs de votre bâtiment.",
      functional_entities_number: "Nombre d'entités fonctionnelles",
      devices_number: "Nombre de compteurs",
      devices_title: "Liste des compteurs",
      devices_tooltip: "Ces compteurs sont les points de mesure de la consommation énergétique de votre bâtiment. Les tantièmes associés permettent de répartir leur consommation entre les bâtiments auxquels ils sont rattachés ainsi que les éventuelles refacturations à un tiers (copropriété, ASL, RIE…).",
      functional_entities_title: "Liste des entités fonctionnelles principales",
      device_code: "Numéro de compteur",
      device_destination: "Parties alimentées",
      device_quality: "Qualité",
      device_usage: "Usage",
      device_tenants: "Locataires",
      device_tenth: "Tantièmes",
      device_tooltip_link: "Voir la fiche du compteur",
      device_automatic_upload: "Type de remontée des données",
      device_upload: "Possibilités de remontée énergétique",
      ef_building: "Bâtiment",
      ef_activity: "Activité",
      ef_tenant_code: "Code locataire",
      ef_tenant: "Locataire",
      ef_surface: "Surface en m²",
      ef_start_date: "Début du bail",
      ef_lots: "Lots",
    },
    fluids: {
      water: "Eau",
      electricity: "Électricité",
      gaz: "Gaz",
      heat: "Réseau de chaleur urbain",
      cold: "Réseau de froid urbain",
      coal: "Charbon",
      wood: "Bois",
      propane: "Propane",
      butane: "Butane",
      fod: "Fioul domestique",
      otherFossileFuel: "Autres combustibles fossiles",
    },
    destination: {
      private: "Partie privative",
      shared: "Partie commune",
      private_vacant: "Partie privative vacante",
      out_of_scope: "Hors périmètre",
      third_party: "Partie tierce",
      private_vacant_shared: "Partic privative vacante ou commune",
      deposited: "Compteur Déposé",
    },
  },
};

export default translateSitesFr;
