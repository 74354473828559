import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import fr from "date-fns/locale/fr";
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import globalUseStyles from '../../styles/globalCustomStyles';

const ChooseMonthButton = ({ title, period, setPeriod, startDate }) => {
    const globalClasses = globalUseStyles();
    const [selected, setSelected] = useState([period.startDate, period.endDate]);

    const onValidateClick = (dateRange) => {
        if (dateRange[0] && dateRange[1]) {
            setPeriod({ startDate: dateRange[0], endDate: dateRange[1] });
        }
    }

    return (
        <div className={globalClasses.calendarPeriods}>
            <Typography variant="h9">{title}</Typography>
            <DatePicker
                showIcon
                selectsRange={true}
                startDate={selected[0]}
                endDate={selected[1]}
                onChange={(update) => {
                    setSelected(update);
                    onValidateClick(update);
                }}
                className={globalClasses.datePicker}
                calendarClassName={globalClasses.datePickerCalendar}
                locale={fr}
                showMonthYearPicker
                maxDate={new Date()}
                minDate={new Date(startDate)}
                dateFormat="MM/yyyy"
                icon={<FontAwesomeIcon icon={faCalendar} className={globalClasses.datePickerIcon} />}
            />
        </div>
    )
}

export default ChooseMonthButton;