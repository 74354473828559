import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { default as React, useEffect, useState } from 'react';
import { useGetList, useRecordContext, useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ChartGauge from '../../aegeon/charts/ChartGauge';
import { getConsumptionVariation, getPeriods } from "../../aegeon/compute_evolution";
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import ChooseMonthButton from '../../Buttons/ChooseMonthButton';
import CustomTable from '../../Table/CustomTable';
import DeviceQualitiesReceiver from '../CustomComponents/DeviceQualitiesReceiver';

const SiteEvolutionDetailsComponent = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const record = useRecordContext();
  const startDate = props.startDate;
  const moment = require('moment');
  const theme = useTheme();

  const {
    data,
    isLoading,
    hasNextPage,
  } = useQueryContext(props);

  const siteId = record?.id;
  const { data: devices, isLoading: isLoadingDevices } = useGetList(
    'devices',
    {
      filter: {
        siteIds: { contains: siteId },
      },
    }
  );

  const reporting_start_date = record?.airtable?.reporting_start_date
  const initialPeriods = getPeriods(reporting_start_date, undefined)

  const [firstPeriod, setFirstPeriod] = useState({ startDate: initialPeriods.previousPeriodStartDate, endDate: initialPeriods.previousPeriodEndDate });
  const [secondPeriod, setSecondPeriod] = useState({ startDate: initialPeriods.currentPeriodStartDate, endDate: initialPeriods.currentPeriodEndDate });
  const [consumptionObject, setConsumptionObject] = useState(null);
  const [missingRowsFirstPeriod, setMissingRowsFirstPeriod] = useState({ energetic: [] });
  const [missingRowsSecondPeriod, setMissingRowsSecondPeriod] = useState({ energetic: [] });
  const [concatenedMissingRows, setConcatenedMissingRows] = useState([]);
  const [quality, setQuality] = useState("A");

  useEffect(() => {
    let concatenedMissingRows = [
      { name: "Numéro de compteur", values: ["Mois manquants période de référence", "Mois manquants période comparée"] }
    ]
    let missingRowsGroupedByDevice = {}
    missingRowsFirstPeriod["energetic"].forEach((row, index) => {
      if (index > 0) {
        if (row.values[0] !== "") {
          missingRowsGroupedByDevice[row.name] = { firstPeriod: row.values[0], secondPeriod: "" }
        }
      }
    })
    missingRowsSecondPeriod["energetic"].forEach((row, index) => {
      if (index > 0) {
        if (row.values[0] !== "") {
          if (!missingRowsGroupedByDevice[row.name]) {
            missingRowsGroupedByDevice[row.name] = { firstPeriod: "", secondPeriod: row.values[0] }
          } else {
            missingRowsGroupedByDevice[row.name].secondPeriod = row.values[0]
          }
        }
      }
    })
    Object.keys(missingRowsGroupedByDevice).forEach((device) => {
      concatenedMissingRows.push({ name: device, values: [missingRowsGroupedByDevice[device].firstPeriod, missingRowsGroupedByDevice[device].secondPeriod] })
    })
    setConcatenedMissingRows(concatenedMissingRows)
  }, [missingRowsFirstPeriod, missingRowsSecondPeriod])


  useEffect(() => {
    if (!isLoading && data && data.items && data.items.length > 0 && !hasNextPage) {
      let timeseries = data.items
      props.setDisabled(false)

      let period = getPeriods(reporting_start_date, timeseries)

      if (firstPeriod.startDate && firstPeriod.endDate && secondPeriod.startDate && secondPeriod.endDate) {
        period = {
          previousPeriodEndDate: firstPeriod.endDate,
          previousPeriodStartDate: firstPeriod.startDate,
          currentPeriodEndDate: secondPeriod.endDate,
          currentPeriodStartDate: secondPeriod.startDate,
        }
      }

      let { consumptionVariation, lastYearDays, currentYearDays, lastYearMonths, currentYearMonths, lastYearConsumption,
        currentYearConsumption } = getConsumptionVariation(timeseries, period)
      setConsumptionObject({
        consumptionVariation, lastYearDays, currentYearDays, lastYearMonths, currentYearMonths, lastYearConsumption,
        currentYearConsumption
      })
      let quality = getComparisonQuality(firstPeriod, secondPeriod, lastYearDays, currentYearDays)
      setQuality(quality)
      props.setDataCallback(timeseries, quality)
    }
  }, [data, isLoading, reporting_start_date, hasNextPage, firstPeriod, secondPeriod])

  const getComparisonQuality = (firstPeriod, secondPeriod, lastYearDays, currentYearDays) => {
    let firstPeriodStart = moment(firstPeriod.startDate).format("DD/MM")
    let firstPeriodEnd = moment(firstPeriod.endDate).format("DD/MM")
    let secondPeriodStart = moment(secondPeriod.startDate).format("DD/MM")
    let secondPeriodEnd = moment(secondPeriod.endDate).format("DD/MM")
    if (firstPeriodStart !== secondPeriodStart || firstPeriodEnd !== secondPeriodEnd) {
      return "D"
    }
    if (lastYearDays !== currentYearDays) {
      return "C"
    }
    if (concatenedMissingRows.length > 1) {
      return "B"
    }
    return "A"
  }

  const quality_colors = {
    "A": theme.palette.gradation.low,
    "B": theme.palette.gradation.midLow,
    "C": theme.palette.gradation.midHigh,
    "D": theme.palette.gradation.high
  }

  const rows = [
    { name: t("devices.evolution.table.quality"), value: quality, tooltip_info: 'devices.evolution.table.quality_tooltip_info', tooltip_info_color: quality_colors[quality] },
    { name: t("devices.evolution.table.reference_period"), value: firstPeriod.startDate ? firstPeriod.startDate.toLocaleDateString(localStorage.getItem("language"), { month: "2-digit", year: "numeric" }) + " - " + firstPeriod.endDate.toLocaleDateString(localStorage.getItem("language"), { month: "2-digit", year: "numeric" }) : " " },
    { name: t("devices.evolution.table.comparison_period"), value: secondPeriod.startDate ? secondPeriod.startDate.toLocaleDateString(localStorage.getItem("language"), { month: "2-digit", year: "numeric" }) + " - " + secondPeriod.endDate.toLocaleDateString(localStorage.getItem("language"), { month: "2-digit", year: "numeric" }) : " " },
    { name: t("devices.evolution.table.months_reference_period"), value: consumptionObject?.lastYearMonths?.join(", ") },
    { name: t("devices.evolution.table.months_comparison_period"), value: consumptionObject?.currentYearMonths?.join(", ") },
    { name: t("devices.evolution.table.last_period_consumption"), value: consumptionObject?.lastYearConsumption?.toFixed(2) },
    { name: t("devices.evolution.table.current_period_consumption"), value: consumptionObject?.currentYearConsumption?.toFixed(2) },
  ]

  return (
    <Card className={`${globalClasses.DetailCard} ${globalClasses.DetailCardTable}`}>
      <Grid2 container direction="row" justifyContent="center" alignItems="center"
        spacing={5}
        style={{ padding: '0 !important' }}
      >
        <Grid2 item="true" small={12} medium={6} big={6} >
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t(`devices.evolution.title`)}</Typography>
            {/* {<CustomTooltip title={t(`devices.evolution.tooltip_text`)} icon={<InfoTooltipIcon />} />} */}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t(`devices.evolution.details_subtitle`)}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <div className={globalClasses.evolutionButtons}>
              <ChooseMonthButton
                title={t(`devices.evolution.first_period`)}
                period={firstPeriod}
                setPeriod={setFirstPeriod}
                startDate={startDate}
              />
              <ChooseMonthButton
                title={t(`devices.evolution.second_period`)}
                period={secondPeriod}
                setPeriod={setSecondPeriod}
                startDate={startDate}
              />
            </div>
            <div style={{ width: "100%", height: "80%" }}>
              <ChartGauge
                consumptionVariation={consumptionObject?.consumptionVariation}
                isLoading={isLoading}
              />
            </div>
          </div>
        </Grid2>
        <Grid2 item="true" small={12} medium={6} big={6} >
          <div className={globalClasses.DetailCardTableTitle}>
            <Typography variant="h8">{t(`devices.evolution.details_title`)}</Typography>
            {/* {<CustomTooltip title={t(`devices.evolution.tooltip_text`)} icon={<InfoTooltipIcon />} />} */}
          </div>
          <div className={globalClasses.DetailCardTableSubtitle}>
            {t(`devices.evolution.details_subtitle_2`)}
          </div>
          <div style={{ width: "100%", height: "550px" }}>
            <CustomTable key="periods" rows={rows} defaultTooltip={"sites.no_data_tooltip"} />
            <div className={globalClasses.evolutionMissingBills}>
              <DeviceQualitiesReceiver
                devices={devices}
                reportingStartDate={moment(firstPeriod.startDate)}
                reportingEndDate={moment(firstPeriod.endDate)}
                period={firstPeriod}
                setRows={(missingRows) => { setMissingRowsFirstPeriod(missingRows) }}
                setPercentage={() => { }}
                setScore={() => { }}
              />
              <DeviceQualitiesReceiver
                devices={devices}
                reportingStartDate={moment(secondPeriod.startDate)}
                reportingEndDate={moment(secondPeriod.endDate)}
                period={secondPeriod}
                setRows={(missingRows) => { setMissingRowsSecondPeriod(missingRows) }}
                setPercentage={() => { }}
                setScore={() => { }}
              />
              {concatenedMissingRows.length > 1 &&
                <CustomTable key="missing" list={true} rows={concatenedMissingRows} defaultTooltip={"sites.no_data_tooltip"} />
              }
            </div>
          </div>
        </Grid2>
      </Grid2>
    </Card>
  )
};

export default SiteEvolutionDetailsComponent;