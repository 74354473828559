import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { calculNotation, calculScore, convertQualitiesIntoRows } from '../../aegeon/compute_missing_bills';
import QueryField from '../../aegeon/query/QueryField';
import { useQueryContext } from '../../aegeon/query/useQueryContext';

const DeviceQualitiesReceiver = (props) => {
    const {
        devices,
        reportingStartDate,
        reportingEndDate,
        setRows,
        setPercentage,
        setScore,
        period
    } = props;

    const [deviceProperties, setDeviceProperties] = React.useState({});
    const t = useTranslate();

    const addDeviceProperties = (deviceId, childData) => {
        if (!Object.keys(deviceProperties).includes(deviceId)) {
            setDeviceProperties({ ...deviceProperties, [deviceId]: childData?.properties?.find((property) => property?.name === "fusioned_energy")?.quality });
        }
    };

    useEffect(() => {
        if ((devices?.length > 0 && devices?.length === Object.keys(deviceProperties).length) || (devices && devices?.length === 0)) {
            let rows_data = convertQualitiesIntoRows(reportingStartDate, reportingEndDate, deviceProperties, devices, t)
            const score = calculScore(rows_data)
            const percentage = calculNotation(score)
            Object.keys(rows_data).forEach(key => {
                rows_data[key].unshift({ name: t('sites.composition.device_code'), values: ["Mois avec donnée manquante", ''] })
            })
            setRows && setRows(rows_data)
            setPercentage && setPercentage(percentage)
            setScore && setScore(score)
        }
    }, [deviceProperties, devices, period]);

    const QualityReceiver = ({ deviceId, addDeviceProperties }) => {
        const {
            data,
            isLoading,
        } = useQueryContext(props);

        useEffect(() => {
            if (!isLoading && data) {
                addDeviceProperties(deviceId, data);
            }
        }, [isLoading, data, addDeviceProperties]);
        return null;
    };


    return <>
        {
            devices && devices?.map((device, index) => {
                return (
                    <QueryField key={index} query_name='getDevice' id={device?.id}>
                        <QualityReceiver deviceId={device?.id} addDeviceProperties={addDeviceProperties} />
                    </QueryField>
                )
            })
        }
    </>
};

export default DeviceQualitiesReceiver;