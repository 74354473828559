import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import ExportButton from '../../Buttons/ExportButton';
import DetailsShow from '../../aegeon/cards/DetailsShow';
import { export_row_to_excel } from '../../aegeon/helper';
import QueryInfiniteField from '../../aegeon/query/QueryInfiniteField';
import SiteEvolutionDetailsComponent from './SiteEvolutionDetailsComponent';

const SiteEvolutionDetails = (props) => {
    const globalClasses = globalUseStyles();
    const t = useTranslate();
    const moment = require('moment');
    const endDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).set({ date: 1 }).subtract(1, 'days');
    const startDate = moment().set({ year: 2019, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
    const [data, setData] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [quality, setQuality] = useState(null);

    const setDataCallback = (data, quality) => {
        setData(data)
        setQuality(quality)
    }

    const handleExport = (name) => {
        var sheets_data = [];
        if (data) {
            const sheetName = "Série AEGEON"
            const quality_note = `${t('devices.evolution.table.quality')} : ${quality} - ${t('devices.evolution.table.quality_note')}`
            let rows = [[quality_note], ["Date", "Consommations (kWh PCS)", "Source"]]
            data.forEach((row) => {
                let date = moment(row.time).format("MM/YYYY")
                let value = Math.round(row.value * 100) / 100
                rows.push([date, value, "Série AEGEON"])
            })
            sheets_data.push({ sheetName: sheetName, data: rows });
            export_row_to_excel(sheets_data, `${t('devices.exports.table')}${name}.xlsx`);
        };
    }


    return (
        <DetailsShow title={t('sites.evolution.title')} resource="sites" lessMargin={true} tooltip={<ExportButton onClick={handleExport} disabled={disabled} />}>
            <QueryInfiniteField query_name="getSiteTimeseries" params={{ time: { between: [startDate, endDate] } }}>
                <SiteEvolutionDetailsComponent setDataCallback={setDataCallback} setDisabled={setDisabled} startDate={startDate} />
            </QueryInfiniteField>
        </DetailsShow>
    )
};

export default SiteEvolutionDetails;