const getPeriods = (startDate, timeseries) => {
  let currentPeriodEndDate = new Date();
  currentPeriodEndDate.setHours(0, 0, 0, 0);
  // get the last day of the previous month to avoid to have an incomplete month to compare
  currentPeriodEndDate.setDate(1);
  currentPeriodEndDate.setDate(currentPeriodEndDate.getDate() - 1);
  let currentPeriodStartDate = new Date();
  if (startDate == null) {
    currentPeriodStartDate.setDate(1);
    currentPeriodStartDate.setMonth(0);
    currentPeriodStartDate.setHours(0, 0, 0, 0);
  } else {
    let day = startDate.split("/")[0];
    let month = startDate.split("/")[1];
    currentPeriodStartDate.setDate(day);
    currentPeriodStartDate.setMonth(month - 1);
    currentPeriodStartDate.setHours(0, 0, 0, 0);
    if (currentPeriodStartDate > currentPeriodEndDate) {
      currentPeriodStartDate.setFullYear(currentPeriodStartDate.getFullYear() - 1);
    }
  }

  // if the period is less than two months, get the previous year's data
  if (currentPeriodEndDate - currentPeriodStartDate < 5184000000) {
    currentPeriodEndDate = new Date(currentPeriodStartDate);
    currentPeriodEndDate.setDate(currentPeriodEndDate.getDate() - 1);
    currentPeriodStartDate.setFullYear(currentPeriodStartDate.getFullYear() - 1);
  }

  let previousPeriodEndDate = new Date(currentPeriodEndDate);
  previousPeriodEndDate.setFullYear(previousPeriodEndDate.getFullYear() - 1);
  let previousPeriodStartDate = new Date(currentPeriodStartDate);
  previousPeriodStartDate.setFullYear(previousPeriodStartDate.getFullYear() - 1);

  return {
    previousPeriodEndDate: previousPeriodEndDate,
    previousPeriodStartDate: previousPeriodStartDate,
    currentPeriodEndDate: currentPeriodEndDate,
    currentPeriodStartDate: currentPeriodStartDate,
  };
};

const getConsumptionVariation = (timeseries, period) => {
  const { previousPeriodEndDate, previousPeriodStartDate, currentPeriodEndDate, currentPeriodStartDate } = period;

  let lastYearConsumption = 0;
  let lastYearDays = 0;
  let currentYearConsumption = 0;
  let currentYearDays = 0;
  let lastYearMonths = [];
  let currentYearMonths = [];

  timeseries.forEach((element) => {
    let date = new Date(element.time);
    if (date >= previousPeriodStartDate && date <= previousPeriodEndDate) {
      lastYearConsumption += parseFloat(element.value);
      lastYearDays += 1;
      lastYearMonths.push(date.toLocaleDateString(localStorage.getItem("language"), { month: "2-digit", year: "numeric" }));
    } 
    if (date >= currentPeriodStartDate && date <= currentPeriodEndDate) {
      currentYearConsumption += parseFloat(element.value);
      currentYearDays += 1;
      currentYearMonths.push(date.toLocaleDateString(localStorage.getItem("language"), { month: "2-digit", year: "numeric" }));
    }
  });
  if (lastYearDays === 0 || currentYearDays === 0) {
    var consumptionVariation = null;
  } else {
    if (lastYearConsumption === 0 && currentYearConsumption === 0) {
      var consumptionVariation = 0;
    }
    else {
      var consumptionVariation = (currentYearConsumption - lastYearConsumption) / lastYearConsumption;
    }
  }
  return { 
    consumptionVariation, 
    lastYearDays, 
    currentYearDays, 
    lastYearMonths, 
    currentYearMonths,
    lastYearConsumption,
    currentYearConsumption,
  };
};

const formatDate = (date) => {
  if (!date) return null;
  return date.toLocaleDateString(localStorage.getItem("language"), { day: "2-digit", month: "2-digit" });
};

const getWarningColor = (lastYearDays, currentYearDays, consumption, step) => {
  if (lastYearDays === 0 || currentYearDays === 0 || !consumption) {
    return "grey";
  } else if (step === "day" && Math.abs(lastYearDays - currentYearDays) > 20) {
    return "red";
  } else if (step === "month" && Math.abs(lastYearDays - currentYearDays) > 0) {
    return "red";
  } else if (Math.abs(lastYearDays - currentYearDays) > 0) {
    return "orange";
  } else {
    return "green";
  }
};

export { getConsumptionVariation, getPeriods, formatDate, getWarningColor };
