import { Card } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { default as React, useEffect, useState } from 'react';
import { useGetList, useRecordContext, useTranslate } from 'react-admin';
import globalUseStyles from '../../../styles/globalCustomStyles';
import TableDJComponent from '../../aegeon/charts/TableDJComponent';
import { getSerieAggFluidDestination, getTableSeries } from '../../aegeon/compute_table';
import QueryField from '../../aegeon/query/QueryField';
import QueryInfiniteField from '../../aegeon/query/QueryInfiniteField';
import { useQueryContext } from '../../aegeon/query/useQueryContext';
import TableDetailsComponent from '../../sites/CardsDetails/TableDetailsComponent';
import TableSiteDevicesDetailsComponent from '../../sites/CardsDetails/TableSiteDevicesDetailsComponent';

const TableCardDetails = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();
  const record = useRecordContext();
  const siteId = record?.id;
  const site_code = record?.airtable?.code;
  const [isDone, setIsDone] = useState(false);
  const [groupedSerie, setGroupedSerie] = useState({});
  const [aggFluidDestination, setAggFluidDestination] = useState({});
  const [dataSources, setDataSources] = useState({});
  const [isLoadingDevices, setIsLoadingDevices] = useState(true);
  const [isLoadingQuery, setIsLoadingQuery] = useState(true);
  const [isLoadingDju, setIsLoadingDju] = useState(true);
  const [isLoadingDjr, setIsLoadingDjr] = useState(true);
  const setDataCallback = props.setDataCallback;
  const setDisabled = props.setDisabled;

  const {
    data,
    isLoading,
  } = useQueryContext(props);

  const moment = require('moment');
  const startDate = moment().subtract(1, 'years').set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  let endDate = moment().set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })

  const djStartDate = moment().set({ year: 2019, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const djEndDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const { data: devices } = useGetList(
    'devices',
    {
      filter: {
        siteIds: { contains: siteId },
      },
    }
  );

  const getLastMonths = (startDate, endDate) => {
    const group_names = {};
    let date = startDate;
    while (date.isBefore(endDate)) {
      const year = date.format("YYYY");
      const month = date.format("MM");
      if (!group_names[year]) {
        group_names[year] = [];
      }
      group_names[year].push(month);
      date = date.clone().add(1, 'month');
    }
    return group_names;
  }

  const setDataSourcesCallback = (childData, source) => {
    if (childData && !dataSources[source]) {
      setDataSources({ ...dataSources, [source]: childData });
    }
  };

  useEffect(() => {
    setDataCallback(dataSources);
  }, [dataSources, setDataCallback]);

  const getPair = (value) => {
    return value % 2 === 0 ? true : false
  }

  const getUnit = (data) => {
    let unit = ''
    if (data && data.items && data.items.length > 0) {
      unit = data.items[0].unit
    }
    return unit
  }

  useEffect(() => {
    if (!isLoading && data && data.items && data.items.length > 0) {
      let groupedSerie = getTableSeries(data.items, false)
      const aggFluidDestination = getSerieAggFluidDestination(data.items, false)
      setGroupedSerie(groupedSerie)
      setAggFluidDestination(aggFluidDestination)
      setIsDone(true)
    } else if (!isLoading && data && data.items && data.items.length === 0) {
      setIsDone(true)
    } else if (!isLoading && data === undefined) {
      setIsDone(true)
    }
  }, [isLoading, data])


  useEffect(() => {
    setIsLoadingQuery(!isDone)
  }, [isDone])

  useEffect(() => {
    let disabled = false;
    if (isLoadingDevices === true || isLoadingQuery === true || isLoadingDju === true || isLoadingDjr === true) {
      disabled = true;
    }
    setDisabled(disabled)
  }, [isLoadingDevices, isLoadingQuery, isLoadingDju, isLoadingDjr, setDisabled]);

  let fluidSourcesList = ["electricity", "gaz", "fod", "heat", "cold"]

  return (
    <Grid2 container spacing={2}>
      <Grid2 item="true" small={12} medium={12} big={12}>
        <Card className={globalClasses.DetailCard}>
          <Grid2 container direction="row" justifyContent="center" alignItems="center"
            spacing={5}
            style={{ padding: '0 !important' }}
          >
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent
                setDataSourcesCallback={setDataSourcesCallback}
                groupedSerie={groupedSerie}
                unit={getUnit(data)}
                isLoading={!isDone}
                title={t("sites.table.total.title")}
                source={t("sites.table.total.title")}
                tooltip_text={t("sites.table.total.tooltip")}
              />
            </Grid2>
            {
              Object.keys(aggFluidDestination).map((fluid_dest, key) => {
                let title = t("sites.fluids." + fluid_dest.split('_')[0]) + ' ' + t("sites.destination." + fluid_dest.split('_').slice(1).join('_'))
                return (
                  <Grid2 item="true" small={12} medium={12} big={6} key={key}>
                    <TableDetailsComponent
                      setDataSourcesCallback={setDataSourcesCallback}
                      groupedSerie={aggFluidDestination[fluid_dest]}
                      unit={getUnit(data)}
                      isLoading={!isDone}
                      title={title}
                      source={title}
                      bottomSource={fluidSourcesList.includes(fluid_dest.split('_')[0]) && t(`sites.table.fluid_sources.${fluid_dest.split('_')[0]}`)}
                    />
                  </Grid2>
                )
              })
            }
            {getPair(Object.keys(aggFluidDestination).length) &&
              <Grid2 item="true" small={12} medium={12} big={6}>
                <></>
              </Grid2>
            }
          </Grid2>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={12}>
        <Card className={globalClasses.DetailCard}>
          <Grid2 container direction="row" justifyContent="center" alignItems="center"
            spacing={5}
            style={{ padding: '0 !important' }}
          >
            <Grid2 item="true" small={12} medium={12} big={6}>
              <QueryField query_name="getSiteDjuTimeseries" params={{ time: { between: [djStartDate, djEndDate] } }}>
                <TableDJComponent
                  type="dju"
                  setDataSourcesCallback={setDataSourcesCallback}
                  setIsLoading={(isLoading) => { setIsLoadingDju(isLoading) }}
                />
              </QueryField>
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <QueryField query_name="getSiteDjrTimeseries" params={{ time: { between: [djStartDate, djEndDate] } }}>
                <TableDJComponent
                  type="djr"
                  setDataSourcesCallback={setDataSourcesCallback}
                  setIsLoading={(isLoading) => { setIsLoadingDjr(isLoading) }}
                />
              </QueryField>
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
              <TableDetailsComponent
                setDataSourcesCallback={setDataSourcesCallback}
                type={"occupation_rate"}
                title={t("sites.table.occupation_rate.title")}
                source={t("sites.table.occupation_rate.title")}
                tooltip_text={t("sites.table.occupation_rate.tooltip")}
              />
            </Grid2>
            <Grid2 item="true" small={12} medium={12} big={6}>
            </Grid2>
          </Grid2>
        </Card>
      </Grid2>
      <Grid2 item="true" small={12} medium={12} big={12}>
        <Card className={globalClasses.DetailCard}>
          <Grid2 container direction="row" justifyContent="center" alignItems="center"
            spacing={5}
            style={{ padding: '0 !important' }}
          >
            <Grid2 item="true" small={12} medium={12} big={12}>
              <QueryInfiniteField query_name="getSiteDevicesTimeseries" params={{ time: { between: [startDate, endDate] } }}>
                <TableSiteDevicesDetailsComponent
                  setDataSourcesCallback={setDataSourcesCallback}
                  devices={devices}
                  site_code={site_code}
                  lastMonths={getLastMonths(startDate, endDate)}
                  title={t("sites.table.devices.title")}
                  source={t("sites.table.devices.title")}
                  setIsLoading={(isLoading) => { setIsLoadingDevices(isLoading) }}
                  tooltip_text={t("sites.table.devices.tooltip")}
                />
              </QueryInfiniteField>
            </Grid2>
          </Grid2>
        </Card>
      </Grid2>
    </Grid2>
  )
};

export default TableCardDetails;