import React from 'react';
import globalUseStyles from '../../../../styles/globalCustomStyles';

import { Card, Typography } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import CustomTable from '../../../Table/CustomTable';
import { value_with_unit } from '../../../aegeon/helper';

const SiteGeneralInformationDetailsContentLeftBottom = (props) => {
  const globalClasses = globalUseStyles();
  const t = useTranslate();

  const record = useRecordContext();

  const energy_quality_count = record?.airtable?.energy_quality_data_2023?.reduce((acc, curr) => {
    if (acc[curr] === undefined) {
      acc[curr] = 1;
    }
    else {
      acc[curr] += 1;
    }
    return acc;
  }, {});

  const energy_quality = energy_quality_count ? Object.entries(energy_quality_count).map(([key, value]) => {
    if (!key) {
      return `Vide (${value})`;
    }
    return `${key} (${value})`;
  }).sort().join(", ") : null;


  const period_of_reporting = (() => {
    if (record?.airtable?.reporting_start_date !== null && record?.airtable?.reporting_end_date !== null) {
      let start = record?.airtable?.reporting_start_date
      let end = record?.airtable?.reporting_end_date
      let value = `${start} - ${end}`
      return value
    }
    else return null
  })()

  const esg_modality = record?.airtable?.esg_modality ? record?.airtable?.esg_modality?.map((item) => item.name).join(", ") : null;

  // The rows tooltips are translated in the CustomTable component
  const rows = [
    { name: t('sites.general_information.period_of_reporting'), value: period_of_reporting, tooltip: 'sites.general_information.period_of_reporting_no_data' },
    { name: t('sites.general_information.reporting_type'), value: esg_modality, tooltip: 'sites.general_information.reporting_type_no_data' },
    { name: t('sites.general_information.synoptic_quality_2023'), value: record?.airtable?.synoptic_quality_reporting_2023, tooltip: 'sites.general_information.synoptic_quality' },
    { name: t('sites.general_information.energy_quality_data'), value: energy_quality, tooltip: 'sites.general_information.energy_quality_data_no_data' },
    { name: t('sites.general_information.deet_subject'), value: record?.airtable?.deet_subject ? "Oui" : "Non", tooltip: 'sites.general_information.deet_subject_no_data', tooltip_info: 'sites.general_information.deet_subject_tooltip_info' },
  ]

  const rows2 = [
    { name: t('sites.general_information.last_reporting_period_surface_consumption'), value: value_with_unit(record?.airtable?.last_reporting_period_surface_consumption, "kWh EF PCI / m²"), tooltip: 'sites.general_information.last_reporting_period_surface_consumption_no_data' },
    { name: t('sites.general_information.reporting_2023_surface_consumption'), value: value_with_unit(record?.airtable?.reporting_2023_surface_consumption, "kWh EF PCI / m²"), tooltip: 'sites.general_information.reporting_2023_surface_consumption_no_data' },
    { name: t('sites.general_information.reporting_2023_total_consumption'), value: value_with_unit(record?.airtable?.reporting_2023_total_consumption, "kWh EF PCI"), tooltip: 'sites.general_information.reporting_2023_total_consumption_no_data' },
    { name: t('sites.general_information.reporting_2023_total_ghg_emissions'), value: value_with_unit(record?.airtable?.reporting_2023_total_ghg_emissions, "kg eq CO₂"), tooltip: 'sites.general_information.reporting_2023_total_ghg_emissions_no_data' },
    { name: t('sites.general_information.reporting_2023_total_water_consumption'), value: record?.airtable?.reporting_2023_total_water_consumption !== 0 ? value_with_unit(record?.airtable?.reporting_2023_total_water_consumption, "m³") : null, tooltip: 'sites.general_information.reporting_2023_total_water_consumption_no_data', hide_if_null: true },
    { name: t('sites.general_information.final_energy_perf_bpe_oid_ref'), value: value_with_unit(record?.airtable?.final_energy_perf_bpe_oid_ref, "kWh EF / m²"), tooltip: 'sites.general_information.final_energy_perf_bpe_oid_ref_no_data' },
    { name: t('sites.general_information.co2_perf_bpe_oid_ref'), value: value_with_unit(record?.airtable?.co2_perf_bpe_oid_ref, "kg eq CO₂ / m²"), tooltip: 'sites.general_information.co2_perf_bpe_oid_ref_no_data' },
    { name: t('sites.general_information.water_perf_bpe_oid_ref'), value: value_with_unit(record?.airtable?.water_perf_bpe_oid_ref, "m³"), tooltip: 'sites.general_information.water_perf_bpe_oid_ref_no_data' },
  ]
  rows.push(...rows2);

  return (
    <Card className={globalClasses.DetailCard}>
      <Typography display="block" variant="h8">
        <span><b>{t('sites.general_information.left_bottom_panel.title')}</b></span>
      </Typography>
      <div className={globalClasses.DetailCardTableSubtitle} />
      <CustomTable rows={rows} defaultTooltip={"sites.no_data_tooltip"} />
    </Card>
  );
};

export default SiteGeneralInformationDetailsContentLeftBottom;

